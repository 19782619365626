<template>
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.99922 1.19922L5.99922 10.7992M10.7992 5.99922L1.19922 5.99922"
        stroke=""
        stroke-width="2"
        stroke-linecap="round"
      />
    </svg>
  </template>
  
  <script>
  export default {
    name: "IconPlusBlue",
  };
  </script>