<template src="./systemDashboard.html"></template>

<script>
import { Button } from "@progress/kendo-vue-buttons";
import IconHome from "@/components/svg-icons/IconHome.vue";
import IconLogout from "@/components/svg-icons/IconLogout.vue";
import IconRegister from "@/components/svg-icons/IconRegister.vue";
import IconEmail from "@/components/svg-icons/IconEmail.vue";
import IconSettings from "@/components/svg-icons/IconSettings.vue";
import AppGlobalLoader from "@/components/commons/app-global-loader/AppGlobalLoader.vue";
import AppWelcome from "@/components/commons/app-welcome/AppWelcome.vue";
import AppSearchBar from "@/components/commons/app-search-bar/AppSearchBar.vue";
import LookupFWRange from "@/components/system-dashboard/lookup-fw-range/LookupFWRange.vue";
import LookupLOCID from "@/components/system-dashboard/lookup-locid/LookupLOCID.vue";
import NewConnectivityReport from "@/components/system-dashboard/new-connectivity-report/NewConnectivityReport";
import NewTechnicalLookup from "@/components/system-dashboard/new-technical-lookup/NewTechnicalLookup";
import NewSiteLookup from "@/components/system-dashboard/new-site-lookup/NewSiteLookup";
import RecentSearches from "@/components/system-dashboard/recent-searches/RecentSearches";
import AppSearchLoader from "@/components/commons/app-search-loader/AppSearchLoader.vue";
import ConrepOutput from "@/components/system-dashboard/conrep-output/ConrepOutput";
import AppNoData from "@/components/commons/app-no-data/AppNoData.vue";
import MenuManagement from "@/components/system-dashboard/menu-management/MenuManagement";
import EmailLogs from "@/components/system-dashboard/email-logs/EmailLogs";
import RegistrationForm from "@/components/system-dashboard/registration-form/RegistrationForm";
import { getFormLinkItems } from "@/services/LocSeach.service";

export default {
  components: {
    "k-button": Button,
    AppGlobalLoader,
    IconHome,
    IconLogout,
    IconRegister,
    IconEmail,
    IconSettings,
    AppWelcome,
    AppSearchBar,
    LookupFWRange,
    LookupLOCID,
    AppSearchLoader,
    AppNoData,
    NewConnectivityReport,
    NewTechnicalLookup,
    NewSiteLookup,
    RecentSearches,
    ConrepOutput,
    MenuManagement,
    EmailLogs,
    RegistrationForm,
  },
  data: function () {
    return {
      count: 0,
      LookupFWRange: false,
      LookupLOCID: false,
      ConnectivityReport: false,
      TechnicalLookup: false,
      SiteLookup: false,
      RecentSearches: false,
      ConrepOutput: false,
      isCLean: false,
      userInfo: JSON.parse(localStorage.getItem("rth_user")),
    };
  },
  mounted() {
    this.$store.dispatch("getConnectivityReportsData", {});
    this.$store.commit("SET_NEWCONNECTIVITYREPORT_COMPONENT_SHOWHIDE", false);
    this.$store.commit("SET_ISEMPTY_STATE", false);
    this.$store.dispatch("getFormLinkData");
    this.$store.dispatch("getEmailList");
  },
  computed: {
    isLookupLOCID() {
      return this.$store.state.locSearch.isLookupLOCID;
    },
    isLookupFwRange() {
      return this.$store.state.locSearch.isLookupFwRange;
    },
    isNewConnectivityReport() {
      return this.$store.state.locSearch.isNewConnectivityReport;
    },
    isEmailLogs() {
      return this.$store.state.locSearch.isEmailLogs;
    },
    isMenuManagement() {
      return this.$store.state.locSearch.isMenuManagement;
    },
    isRegistrationForm() {
      return this.$store.state.locSearch.isRegistrationForm;
    },
    isNewTechnicalLookup() {
      return this.$store.state.locSearch.isNewTechnicalLookup;
    },
    isRecentSearch() {
      return this.$store.state.locSearch.isRecentSearch;
    },
    isNewSiteLookup() {
      return this.$store.state.locSearch.isNewSiteLookup;
    },
    isLoading() {
      return this.$store.state.locSearch.isLoading;
    },
    isGlobalLoading() {
      return this.$store.state.locSearch.isGlobalLoading;
    },
    isTechinalLookupOutputLoading() {
      return this.$store.state.locSearch.isTechinalLookupOutputLoading;
    },
    isConnectivityLookupDataLoading() {
      return this.$store.state.locSearch.isConnectivityLookupDataLoading;
    },
    isEmptyState() {
      return this.$store.state.locSearch.isEmptyState;
    },
    notificationObject() {
      return this.$store.state.locSearch.notificationData;
    },
    isConnectivitySaveLoading() {
      return this.$store.state.locSearch.isConnectivitySaveLoading;
    },
    isConnectivityReportLoading() {
      return this.$store.state.locSearch.isConnectivityReportLoading;
    },
    isConRepOutputModalVisible() {
      return this.$store.state.locSearch.isConRepOutputModalVisible;
    },
    isRegenerateConnectivityReportLoading() {
      return this.$store.state.locSearch.isRegenerateConnectivityReportLoading;
    },
    formItems() {
      return this.$store.state.locSearch.formLinkItems;
    },
  },
  methods: {
    isActive(route) {
      return this.$route.path.includes(route) ? "active" : "";
    },
    logoutHandler() {
      // console.log('call');
      // localStorage.removeItem('rth_session');
      // localStorage.removeItem('rth_refreshToken');
      this.$store.dispatch("signOut");
    },
    handleImageClick() {
      this.$router.go("/home");
    },
    cleanData() {
      this.isCLean = true;
    },
    refreshCleanData() {
      this.isCLean = false;
    },
    menuManagementClick() {
      if(this.formItems?.length == 0){
        this.$store.commit("SET_LookupLOCID_COMPONENT_SHOWHIDE", false);
        this.$store.commit("SET_LOOKUPFWRANGE_COMPONENT_SHOWHIDE", false);
        this.$store.commit("SET_NEWTECHNICALLOOKUP_COMPONENT_SHOWHIDE", false);
        this.$store.commit("SET_NEWCONNECTIVITYREPORT_COMPONENT_SHOWHIDE", false);
        this.$store.commit("SET_NEWSITELOOKUP_COMPONENT_SHOWHIDE", false);
        this.$store.commit("SET_RECENTSEARCH_COMPONENT_SHOWHIDE", false);
        this.$store.commit("SET_REGISTRATION_FORM_COMPONENT_SHOWHIDE", false);
        this.$store.commit("SET_EMAIL_LOGS_COMPONENT_SHOWHIDE", false);
        this.$store.commit("SET_REGISTRATION_FORM_COMPONENT_SHOWHIDE", false);
        this.$store.commit("SET_CONREP_OUTPUT_MODAL_VISIBILITY", false);
        this.$store.commit("SET_MENU_MANAGEMENT_COMPONENT_SHOWHIDE", true);
      }else if(this.formItems?.length > 0){
        this.$store.commit("SET_LookupLOCID_COMPONENT_SHOWHIDE", false);
        this.$store.commit("SET_LOOKUPFWRANGE_COMPONENT_SHOWHIDE", false);
        this.$store.commit("SET_NEWTECHNICALLOOKUP_COMPONENT_SHOWHIDE", false);
        this.$store.commit("SET_NEWCONNECTIVITYREPORT_COMPONENT_SHOWHIDE", false);
        this.$store.commit("SET_NEWSITELOOKUP_COMPONENT_SHOWHIDE", false);
        this.$store.commit("SET_RECENTSEARCH_COMPONENT_SHOWHIDE", false);
        this.$store.commit("SET_REGISTRATION_FORM_COMPONENT_SHOWHIDE", false);
        this.$store.commit("SET_EMAIL_LOGS_COMPONENT_SHOWHIDE", false);
        this.$store.commit("SET_MENU_MANAGEMENT_COMPONENT_SHOWHIDE", false);
        this.$store.commit("SET_CONREP_OUTPUT_MODAL_VISIBILITY", false);
        this.$store.commit("SET_REGISTRATION_FORM_COMPONENT_SHOWHIDE", true);
      }
    },
    dashboardClick() {
      this.$store.commit("SET_REGISTRATION_FORM_COMPONENT_SHOWHIDE", false);
      this.$store.commit("SET_MENU_MANAGEMENT_COMPONENT_SHOWHIDE", false);
      this.$store.commit("SET_EMAIL_LOGS_COMPONENT_SHOWHIDE", false);
      this.$store.commit("SET_NEWCONNECTIVITYREPORT_COMPONENT_SHOWHIDE", false);
      this.$store.commit("SET_RECENTSEARCH_COMPONENT_SHOWHIDE", true);
    },
    emailLogsClick() {
      this.$store.commit("SET_RECENTSEARCH_COMPONENT_SHOWHIDE", false);
      this.$store.commit("SET_REGISTRATION_FORM_COMPONENT_SHOWHIDE", false);
      this.$store.commit("SET_MENU_MANAGEMENT_COMPONENT_SHOWHIDE", false);
      this.$store.commit("SET_NEWCONNECTIVITYREPORT_COMPONENT_SHOWHIDE", false);
      this.$store.commit("SET_EMAIL_LOGS_COMPONENT_SHOWHIDE", true);
      this.$store.commit("SET_LookupLOCID_COMPONENT_SHOWHIDE", false);
      this.$store.commit("SET_LOOKUPFWRANGE_COMPONENT_SHOWHIDE", false);
      this.$store.commit("SET_NEWTECHNICALLOOKUP_COMPONENT_SHOWHIDE", false);
      this.$store.commit("SET_NEWSITELOOKUP_COMPONENT_SHOWHIDE", false);
      this.$store.commit("SET_CONREP_OUTPUT_MODAL_VISIBILITY", false);
    },
    registrationFormClick(item) {
      let url = item.link.trim();
      if (!url.startsWith('http://') && !url.startsWith('https://')) {
        url = `http://${url}`;
      }
      window.open(url, '_blank');
    },
  },
};
</script>