<template src="./EmailLogs.html"></template>

<script>
import { Grid } from "@progress/kendo-vue-grid";
import { Button } from "@progress/kendo-vue-buttons";
import IconNoLink from "@/components/svg-icons/IconNoLink.vue";
import IconPlus from "@/components/svg-icons/IconPlus.vue";
import AddLinkModal from "@/components/commons/modals/add-Link-modal/AddLinkModal.vue";
import { dateFormatter } from "@/helpers/utility";
import moment from "moment";

export default {
  components: {
    "k-button": Button,
    grid: Grid,
    IconNoLink,
    IconPlus,
    AddLinkModal,
  },
  data() {
    return {
      showLinkModals: false,
      columns: [
        { field: "created_at", title: 'Data/Time', cell: 'timeTemplate' },
        { field: "sender", title: 'Sender' },
        { field: "recipient", title: 'Recipient' },
        { field: "subject", title: "Subject" },
        { field: "status", title: "Scan Result/Status", cell: 'actionsTemplate' },
      ],
      take: 10,
      gridPageable: {
        info: true,
        previousNext: true,
      },
    };
  },
  computed: {
    emailList(){
      return this.$store.state.locSearch.emails;
    },
    total() {
      return this.$store.state.locSearch.email?.total || 0
    },
    skip() {
      return this.$store.state.locSearch.emails?.from - 1 || 0;
    },
    currentPage() {
      return this.$store.state.locSearch.emails?.current_page || 1;
    },
  },
  mounted(){
    // this.$store.dispatch("getEmailList");
  },
  methods: {
    pageChangeHandler(e) {
      this.$store.dispatch("getEmailList", {
        page: (e?.page?.skip + e?.page?.take) / this.take
      });
    },
    formatDate(date) {
      const toFormat = moment(date)
      const formattedDate = toFormat.format('ddd, MMM DD, YYYY hh:mm A');
      return formattedDate;
    },
    capitalizeStatus(status) {
      let newStatus = status;
      if(newStatus == "bounce"){
        newStatus = "bounced";
      }
      if(newStatus == "open"){
        newStatus = "opened";
      }
      return newStatus.charAt(0).toUpperCase() + newStatus.slice(1);
    },
  },
};
</script>