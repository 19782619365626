import {
  connectivitySaveService,
  createFormLinkItem,
  getAddressCoordinates,
  getAddressSearchStructured,
  getAddressStates,
  getAddressStreets,
  getAddressUnits,
  getConnectivityDetailsService,
  getConnectivityLookupOutputService,
  getConnectivityReports,
  getConnectivityReportService,
  getEmails,
  getFormLinkItems,
  getLookFWRangeDetails,
  getLookuplocIdAddressSearch,
  getLookupLOCIDOutput,
  getNewSiteLookupOutput,
  getSiteLookupServices,
  getTechnicalLookupOutput,
} from "@/services/LocSeach.service";

export type State = {
  locations: [];
  locationLocIdOutput: [];
  isLookupLOCID: boolean;
  locationFwRangeOutput: [];
  isLookupFwRange: boolean;
  addressUnitList: [];
  addressStatesList: [];
  addressStreetsList: [];
  isLoading: boolean;
  isGlobalLoading: boolean;
  isAddressLoading: boolean;
  isEmptyState: boolean;
  isNewConnectivityReport: boolean;
  isMenuManagement: boolean;
  isEmailLogs: boolean;
  isNewTechnicalLookup: boolean;
  isNewSiteLookup: boolean;
  isRecentSearch: boolean;
  isRegistrationForm: boolean;
  getTechnicalLookupOutput: [];
  isTechinalLookupOutputLoading: boolean;
  siteLookupServicesData: [];
  isSiteLookupServicesLoading: boolean;
  getNewSiteLookupOutputdata: [];
  getConnectivityReportsData: [];

  locationData: any;
  markerLocationsData: any;
  connectivityLookupData: any;
  connectivityResponse: any;
  preservedConnectivityLookupData: any;
  isConnectivityLookupDataLoading: boolean;
  isConnectivitySaveLoading: boolean;

  appSearchData: any;
  notificationData: any;

  isConRepOutputModalVisible: boolean;
  conrepOutputData: any;

  connectivityReportData: any;
  isConnectivityReportLoading: boolean;

  switchToConnectivityReportId: any;

  regenerateConnectivityReportData: any;
  searchDataFromConnectivityDetails: any;
  isRegenerateConnectivityReportLoading: boolean;

  globalSearchData: any;
  addressMapLocationData: any;

  selectedAddressForSearch: any;
  errorArrayForGlobalSearch: any;

  formLinkItems: [];
  showLinkModal: boolean;
  showEmailModal: Boolean;
  emails: [];
};

const state: State = {
  locations: [],
  locationLocIdOutput: [],
  isLookupLOCID: false,
  locationFwRangeOutput: [],
  isLookupFwRange: false,
  addressUnitList: [],
  addressStatesList: [],
  addressStreetsList: [],
  isLoading: false,
  isGlobalLoading: false,
  isAddressLoading: false,
  isEmptyState: true,
  isNewConnectivityReport: false,
  isMenuManagement: false,
  isEmailLogs: false,
  isNewTechnicalLookup: false,
  isNewSiteLookup: false,
  isRecentSearch: false,
  isRegistrationForm: false,
  getTechnicalLookupOutput: [],
  isTechinalLookupOutputLoading: false,
  siteLookupServicesData: [],
  isSiteLookupServicesLoading: false,
  getNewSiteLookupOutputdata: [],
  getConnectivityReportsData: [],

  locationData: {},
  markerLocationsData: [],
  connectivityLookupData: {},
  connectivityResponse: {},
  preservedConnectivityLookupData: {},
  isConnectivityLookupDataLoading: false,
  isConnectivitySaveLoading: false,
  appSearchData: {},
  notificationData: {
    visibility: false,
    message: "",
    type: "success",
  },

  isConRepOutputModalVisible: false,
  conrepOutputData: {},

  connectivityReportData: {},
  isConnectivityReportLoading: false,

  switchToConnectivityReportId: null,

  regenerateConnectivityReportData: {},
  searchDataFromConnectivityDetails: {},
  isRegenerateConnectivityReportLoading: false,

  globalSearchData: {
    searchType: null,
    coordinates: "",
    address: {
      id: "",
      formattedAddress: "",
    },
    antennaHeight: "",
  },
  addressMapLocationData: {},
  selectedAddressForSearch: {},
  errorArrayForGlobalSearch: [],
  formLinkItems: [],
  showLinkModal: false,
  showEmailModal: false,
  emails: [],
};

const locSearch = {
  state,
  getters: {},
  mutations: {
    SET_LOOKUPLOC_ID_LOCATIONS(state: { locations: [] }, data: any) {
      state.locations = data;
    },
    SET_LOOKUPLOC_ID_OUTPUT(state: { locationLocIdOutput: [] }, data: any) {
      state.locationLocIdOutput = data;
    },
    SET_LookupLOCID_COMPONENT_SHOWHIDE(
      state: { isLookupLOCID: boolean },
      data: any
    ) {
      state.isLookupLOCID = data;
    },
    SET_GETLOOK_FWRANGE_DETAILS(
      state: { locationFwRangeOutput: [] },
      data: any
    ) {
      state.locationFwRangeOutput = data;
    },
    SET_LOOKUPFWRANGE_COMPONENT_SHOWHIDE(
      state: { isLookupFwRange: boolean },
      data: any
    ) {
      state.isLookupFwRange = data;
    },
    SET_ADDRESS_UNIT_LIST(state: { addressUnitList: [] }, data: any) {
      state.addressUnitList = data;
    },
    SET_ADDRESS_STATES_LIST(state: { addressStatesList: [] }, data: any) {
      state.addressStatesList = data;
    },
    SET_ADDRESS_STREETS_LIST(state: { addressStreetsList: [] }, data: any) {
      state.addressStreetsList = data;
    },
    SET_ISLOADING_LOCSEARCH(state: { isLoading: boolean }, data: any) {
      state.isLoading = data;
    },
    SET_ISGLOBALLOADING_LOCSEARCH(
      state: { isGlobalLoading: boolean },
      data: any
    ) {
      state.isGlobalLoading = data;
    },
    SET_ISSEARCHLOADING_LOCSEARCH(
      state: { isAddressLoading: boolean },
      data: any
    ) {
      state.isAddressLoading = data;
    },
    SET_ISEMPTY_STATE(state: { isEmptyState: boolean }, data: any) {
      state.isEmptyState = data;
    },
    SET_NEWCONNECTIVITYREPORT_COMPONENT_SHOWHIDE(
      state: { isNewConnectivityReport: boolean },
      data: any
    ) {
      state.isNewConnectivityReport = data;
    },
    SET_MENU_MANAGEMENT_COMPONENT_SHOWHIDE(
      state: {isMenuManagement: boolean},
      data: any
      ) {
      state.isMenuManagement = data;
    },
    SET_EMAIL_LOGS_COMPONENT_SHOWHIDE(
      state: {isEmailLogs: boolean},
      data: any
      ) {
      state.isEmailLogs = data;
    },
    SET_NEWTECHNICALLOOKUP_COMPONENT_SHOWHIDE(
      state: { isNewTechnicalLookup: boolean },
      data: any
    ) {
      state.isNewTechnicalLookup = data;
    },
    SET_NEWSITELOOKUP_COMPONENT_SHOWHIDE(
      state: { isNewSiteLookup: boolean },
      data: any
    ) {
      state.isNewSiteLookup = data;
    },
    SET_RECENTSEARCH_COMPONENT_SHOWHIDE(
      state: { isRecentSearch: boolean },
      data: any
    ) {
      state.isRecentSearch = data;
    },
    SET_REGISTRATION_FORM_COMPONENT_SHOWHIDE(
      state: { isRegistrationForm: boolean },
      data: any
    ) {
      state.isRegistrationForm = data;
    },
    SET_GETTECHNICALLOOKUPOUTPUT_RESULT(
      state: { getTechnicalLookupOutput: [] },
      data: any
    ) {
      state.getTechnicalLookupOutput = data;
    },
    SET_TECHNICALLOOKUPOUTPUT_LOADING(
      state: { isTechinalLookupOutputLoading: boolean },
      data: any
    ) {
      state.isTechinalLookupOutputLoading = data;
    },
    SET_GETSITELOOKUPSERVICES_RESULT(
      state: { siteLookupServicesData: [] },
      data: any
    ) {
      state.siteLookupServicesData = data;
    },
    SET_SITELOOKUPSERVICES_LOADING(
      state: { isSiteLookupServicesLoading: boolean },
      data: any
    ) {
      state.isSiteLookupServicesLoading = data;
    },
    SET_NEWSITELOOKUPOUTPUT_RESULT(
      state: { getNewSiteLookupOutputdata: [] },
      data: any
    ) {
      state.getNewSiteLookupOutputdata = data;
    },
    SET_GETCONNECTIVITYREPORTSDATA_RESULT(
      state: { getConnectivityReportsData: [] },
      data: any
    ) {
      state.getConnectivityReportsData = data;
    },
    SET_LOCATION_DATA(state: { locationData: any }, data: any) {
      state.locationData = data;
    },
    SET_MARKER_LOCATIONS_DATA(state: { markerLocationsData: any }, data: any) {
      state.markerLocationsData = data;
    },
    SET_CONNECTIVITY_LOOKUP_DATA(
      state: { connectivityLookupData: any },
      data: any
    ) {
      state.connectivityLookupData = data;
    },
    SET_CONNECTIVITY_DATA_RESPONSE(
      state: { connectivityResponse: any },
      data: any
    ) {
      state.connectivityResponse = data;
    },
    SET_PRESERVED_CONNECTIVITY_LOOKUP_DATA(
      state: { preservedConnectivityLookupData: any },
      data: any
    ) {
      state.preservedConnectivityLookupData = data;
    },
    SET_CONNECTIVITY_LOOKUP_DATA_LOADING(
      state: { isConnectivityLookupDataLoading: boolean },
      data: any
    ) {
      state.isConnectivityLookupDataLoading = data;
    },
    SET_APP_SEARCH_DATA(state: { appSearchData: any }, data: any) {
      state.appSearchData = data;
    },
    SET_CONNECTIVITY_SAVE_LOADING(
      state: { isConnectivitySaveLoading: boolean },
      data: any
    ) {
      state.isConnectivitySaveLoading = data;
    },
    SET_NOTIFICATION_DATA(state: { notificationData: any }, data: any) {
      state.notificationData = data;
      const timerID = setTimeout(() => {
        state.notificationData = {
          visibility: false,
          message: "",
          type: "success",
        };
        clearTimeout(timerID);
      }, 4000);
    },
    SET_CONREP_OUTPUT_DATA(state: { conRepOutputData: any }, data: any) {
      state.conRepOutputData = data;
    },
    SET_CONREP_OUTPUT_MODAL_VISIBILITY(
      state: { isConRepOutputModalVisible: boolean },
      data: any
    ) {
      state.isConRepOutputModalVisible = data;
    },
    SET_CONNECTIVITY_REPORT_DATA(
      state: { connectivityReportData: any },
      data: any
    ) {
      state.connectivityReportData = data;
    },
    SET_CONNECTIVITY_REPORT_DATA_LOADING(
      state: { isConnectivityReportLoading: boolean },
      data: any
    ) {
      state.isConnectivityReportLoading = data;
    },

    SET_SWITCH_TO_CONNECTIVITY_REPORT_ID(
      state: { switchToConnectivityReportId: boolean },
      data: any
    ) {
      state.switchToConnectivityReportId = data;
    },

    SET_REGENERATE_CONNECTIVITY_REPORT_DATA(
      state: { regenerateConnectivityReportData: any },
      data: any
    ) {
      state.regenerateConnectivityReportData = data;
    },

    SET_REGENERATE_CONNECTIVITY_REPORT_DATA_LOADING(
      state: { isRegenerateConnectivityReportLoading: boolean },
      data: any
    ) {
      state.isRegenerateConnectivityReportLoading = data;
    },

    SET_SEARCHDATA_FROM_CONNECTIVITY_DETAILS(
      state: { searchDataFromConnectivityDetails: any },
      data: any
    ) {
      state.searchDataFromConnectivityDetails = data;
    },

    SET_GLOBAL_SEARCH_DATA(state: { globalSearchData: any }, data: any) {
      state.globalSearchData = {
        ...state.globalSearchData,
        ...data,
      };
    },

    SET_ADDRESS_MAP_LOCATION_DATA(
      state: { addressMapLocationData: any },
      data: any
    ) {
      state.addressMapLocationData = data;
    },

    SET_SELECTED_ADDRESS_FOR_SEARCH(
      state: { selectedAddressForSearch: any },
      data: any
    ) {
      state.selectedAddressForSearch = data;
    },
    SET_ERROR_ARRAY_FOR_GLOBAL_SEARCH(
      state: { errorArrayForGlobalSearch: any },
      data: any
    ) {
      if (data?.key) {
        state.errorArrayForGlobalSearch[data?.key] = data?.value;
      } else {
        state.errorArrayForGlobalSearch = [];
      }
    },
    SET_FORM_LINK_ITEMS(state: {formLinkItems: any}, data: any){
      state.formLinkItems = data;
    },
    SET_SHOW_LINK_MODAL(state: {showLinkModal: any}, data: any){
      state.showLinkModal = data;
    },
    SET_SHOW_EMAIL_MODAL(state: {showEmailModal: any}, data: any){
      state.showEmailModal = data;
    },
    SET_EMAILS(state: {emails: any}, data: any){
      state.emails = data;
    }
  },
  actions: {
    getLookuplocIdAddressSearch({ commit }: any, payload: any) {
      // console.log(payload, "payload");

      getLookuplocIdAddressSearch(payload?.value)
        .then((response) => {
          // console.log(response, "getLookuplocIdAddressSearch");
          commit("SET_ISGLOBALLOADING_LOCSEARCH", false);
          commit("SET_ISSEARCHLOADING_LOCSEARCH", false);
          commit(
            "SET_LOOKUPLOC_ID_LOCATIONS",
            response?.data?.data?.suggestions
          );
          commit(
            "SET_SELECTED_ADDRESS_FOR_SEARCH",
            response?.data?.data?.suggestions[0]
          );
          commit("SET_ADDRESS_MAP_LOCATION_DATA", {
            lat: +response?.data?.data?.suggestions[0]?.lat,
            lng: +response?.data?.data?.suggestions[0]?.lng,
          });
        })
        .catch(() => {
          // commit("LOADING_STATUS", false);
        });
    },
    getLookupLOCIDOutput({ commit }: any, payload: any) {
      commit("SET_LookupLOCID_COMPONENT_SHOWHIDE", false);
      getLookupLOCIDOutput(payload)
        .then((response) => {
          commit("SET_ISLOADING_LOCSEARCH", false);
          commit("SET_ISEMPTY_STATE", false);
          commit("SET_LOOKUPLOC_ID_OUTPUT", response?.data?.data.details);
          commit(
            "SET_LookupLOCID_COMPONENT_SHOWHIDE",
            response?.data?.data.details ? true : false
          );
          commit("SET_RECENTSEARCH_COMPONENT_SHOWHIDE", false);
        })
        .catch(() => {
          commit("SET_ISLOADING_LOCSEARCH", false);
          commit("SET_ISEMPTY_STATE", false);
        });
    },
    getLookFWRangeDetails({ commit }: any, payload: any) {
      commit("SET_LOOKUPFWRANGE_COMPONENT_SHOWHIDE", false);
      getLookFWRangeDetails(payload)
        .then((response) => {
          commit("SET_ISLOADING_LOCSEARCH", false);
          commit("SET_ISEMPTY_STATE", false);
          // console.log(response?.data?.data.details);
          if (response?.data?.data.details == "NO_SITES_DISTANCE") {
            commit("SET_ISEMPTY_STATE", true);
          } else {
            commit("SET_GETLOOK_FWRANGE_DETAILS", response?.data?.data.details);
            commit("SET_LOOKUPFWRANGE_COMPONENT_SHOWHIDE", true);
          }
        })
        .catch(() => {
          commit("SET_ISLOADING_LOCSEARCH", false);
          commit("SET_ISEMPTY_STATE", false);
        });
    },
    getAddressUnitList({ commit }: any) {
      getAddressUnits()
        .then((response) => {
          commit("SET_ADDRESS_UNIT_LIST", response?.data?.data?.units);
        })
        .catch(() => {
          // commit("LOADING_STATUS", false);
        });
    },
    getAddressStatesList({ commit }: any) {
      getAddressStates()
        .then((response) => {
          commit("SET_ADDRESS_STATES_LIST", response?.data?.data?.states);
        })
        .catch(() => {
          // commit("LOADING_STATUS", false);
        });
    },
    getAddressStreetsList({ commit }: any) {
      getAddressStreets()
        .then((response) => {
          commit("SET_ADDRESS_STREETS_LIST", response?.data?.data?.streets);
        })
        .catch(() => {
          // commit("LOADING_STATUS", false);
        });
    },
    getAddressSearchStructured({ commit }: any, payload: any) {
      getAddressSearchStructured(payload)
        .then((response) => {
          commit("SET_ISGLOBALLOADING_LOCSEARCH", false);
          commit(
            "SET_LOOKUPLOC_ID_LOCATIONS",
            response?.data?.data?.suggestions
          );
          // commit("SET_ADDRESS_STREETS_LIST",response?.data?.data?.streets);
        })
        .catch(() => {
          commit("SET_ISGLOBALLOADING_LOCSEARCH", false);
        });
    },
    getTechnicalLookupOutput({ commit }: any, payload: any) {
      commit("SET_TECHNICALLOOKUPOUTPUT_LOADING", true);
      commit("SET_APP_SEARCH_DATA", payload);
      getTechnicalLookupOutput(payload)
        .then((response) => {
          commit("SET_TECHNICALLOOKUPOUTPUT_LOADING", false);
          commit("SET_GETTECHNICALLOOKUPOUTPUT_RESULT", response?.data?.data);
          const data = response?.data?.data;
          const locations: any = [];
          if (data?.details?.length > 0) {
            const filtered = data?.details.filter(
              (item: any) => item?.id == 7
            )[0];

            filtered?.details.map((tower: any) => {
              locations.push({
                lat: +tower?.tower_lat,
                lng: +tower?.tower_long,
                service: tower?.service,
              });
            });
          }
          commit("SET_MARKER_LOCATIONS_DATA", locations);
          commit("SET_ISEMPTY_STATE", false);
          commit("SET_RECENTSEARCH_COMPONENT_SHOWHIDE", false);
          commit("SET_NEWTECHNICALLOOKUP_COMPONENT_SHOWHIDE", true);
        })
        .catch(() => {
          commit("SET_TECHNICALLOOKUPOUTPUT_LOADING", false);
          commit("SET_ISGLOBALLOADING_LOCSEARCH", false);
        });
    },
    getConnectivityLookupOutput({ commit }: any, payload: any) {
      commit("SET_CONNECTIVITY_LOOKUP_DATA_LOADING", true);
      commit("SET_NEWCONNECTIVITYREPORT_COMPONENT_SHOWHIDE", false);
      commit("SET_NEWTECHNICALLOOKUP_COMPONENT_SHOWHIDE", false);
      commit("SET_CONREP_OUTPUT_MODAL_VISIBILITY", false);
      commit("SET_APP_SEARCH_DATA", payload);
      getConnectivityLookupOutputService(payload)
        .then((response) => {
          const data = response?.data?.data;
          const locations: any = [];
          if (data?.details?.length > 0) {
            const filtered = data?.details.filter(
              (item: any) => item?.id == 7
            )[0];

            filtered?.details.map((tower: any) => {
              locations.push({
                lat: +tower?.tower_lat,
                lng: +tower?.tower_long,
                service: tower?.service,
              });
            });
          }
          commit("SET_MARKER_LOCATIONS_DATA", locations);
          commit("SET_CONNECTIVITY_LOOKUP_DATA_LOADING", false);
          commit("SET_CONNECTIVITY_LOOKUP_DATA", response?.data?.data);
          commit(
            "SET_PRESERVED_CONNECTIVITY_LOOKUP_DATA",
            response?.data?.data
          );
          commit("SET_ISEMPTY_STATE", false);
          commit("SET_RECENTSEARCH_COMPONENT_SHOWHIDE", false);
          commit("SET_NEWCONNECTIVITYREPORT_COMPONENT_SHOWHIDE", true);
        })
        .catch(() => {
          commit("SET_CONNECTIVITY_LOOKUP_DATA_LOADING", false);
          commit("SET_ISGLOBALLOADING_LOCSEARCH", false);
        });
    },
    saveConnectivityData({ commit }: any, payload: any) {
      commit("SET_CONNECTIVITY_SAVE_LOADING", true);
      connectivitySaveService(payload)
        .then((response) => {
          commit("SET_CONNECTIVITY_SAVE_LOADING", false);
          commit("SET_ISEMPTY_STATE", false);
          commit("SET_RECENTSEARCH_COMPONENT_SHOWHIDE", false);
          commit("SET_NEWCONNECTIVITYREPORT_COMPONENT_SHOWHIDE", false);
          commit("SET_CONREP_OUTPUT_MODAL_VISIBILITY", true);
          commit("SET_CONREP_OUTPUT_DATA", response?.data?.data);
          commit("SET_NOTIFICATION_DATA", {
            type: "success",
            message: response?.data?.message,
            visibility: true,
          });
        })
        .catch(() => {
          commit("SET_NOTIFICATION_DATA", {
            type: "error",
            message: "Something went wrong",
            visibility: true,
          });
          commit("SET_CONNECTIVITY_SAVE_LOADING", false);
          commit("SET_ISGLOBALLOADING_LOCSEARCH", false);
        });
    },
    getSiteLookupServicesData({ commit }: any, payload: any) {
      commit("SET_SITELOOKUPSERVICES_LOADING", true);
      getSiteLookupServices()
        .then((response) => {
          commit(
            "SET_GETSITELOOKUPSERVICES_RESULT",
            response?.data?.data?.services
          );
          commit("SET_SITELOOKUPSERVICES_LOADING", false);
          commit("SET_ISEMPTY_STATE", false);
          commit("SET_RECENTSEARCH_COMPONENT_SHOWHIDE", false);
        })
        .catch(() => {
          commit("SET_SITELOOKUPSERVICES_LOADING", false);
          commit("SET_ISGLOBALLOADING_LOCSEARCH", false);
        });
    },

    getNewSiteLookupOutput({ commit }: any, payload: any) {
      getNewSiteLookupOutput()
        .then((response) => {
          commit(
            "SET_NEWSITELOOKUPOUTPUT_RESULT",
            response?.data?.data?.details
          );
          commit("SET_ISEMPTY_STATE", false);
          commit("SET_NEWSITELOOKUP_COMPONENT_SHOWHIDE", true);
        })
        .catch(() => {
          commit("SET_ISGLOBALLOADING_LOCSEARCH", false);
        });
    },
    getConnectivityReports({ commit }: any, payload: any) {
      getConnectivityReports()
        .then((response) => {
          if (payload == 1)
            commit("SET_NEWTECHNICALLOOKUP_COMPONENT_SHOWHIDE", false);
          else commit("SET_NEWSITELOOKUP_COMPONENT_SHOWHIDE", false);
          commit("SET_RECENTSEARCH_COMPONENT_SHOWHIDE", true);
          commit(
            "SET_GETCONNECTIVITYREPORTSDATA_RESULT",
            response?.data?.data?.data
          );
        })
        .catch(() => {
          commit("SET_ISGLOBALLOADING_LOCSEARCH", false);
        });
    },
    getConnectivityReportsData({ commit }: any, payload: any) {
      commit("SET_CONNECTIVITY_REPORT_DATA_LOADING", true);
      getConnectivityReportService(payload)
        .then((response) => {
          commit("SET_CONNECTIVITY_REPORT_DATA", response?.data?.data);
          commit("SET_NEWTECHNICALLOOKUP_COMPONENT_SHOWHIDE", false);
          commit("SET_NEWSITELOOKUP_COMPONENT_SHOWHIDE", false);
          commit("SET_RECENTSEARCH_COMPONENT_SHOWHIDE", true);
          commit("SET_CONNECTIVITY_REPORT_DATA_LOADING", false);
          commit("SET_CONREP_OUTPUT_MODAL_VISIBILITY", false);
        })
        .catch(() => {
          commit("SET_CONNECTIVITY_REPORT_DATA_LOADING", false);
          commit("SET_ISGLOBALLOADING_LOCSEARCH", false);
        });
    },
    getRegenerateConnectivityReport({ commit }: any, payload: any) {
      commit("SET_REGENERATE_CONNECTIVITY_REPORT_DATA_LOADING", true);
      getConnectivityDetailsService(payload)
        .then((response) => {
          commit("SET_REGENERATE_CONNECTIVITY_REPORT_DATA_LOADING", false);
          commit("SET_RECENTSEARCH_COMPONENT_SHOWHIDE", false);
          commit("SET_ISLOADING_LOCSEARCH", false);
          commit("SET_ISEMPTY_STATE", false);
          // console.log(response?.data?.data, "c92");
          commit("SET_APP_SEARCH_DATA", {
            location_id: response?.data?.data?.searchData?.location_id,
            latitude: response?.data?.data?.searchData?.lat,
            longitude: response?.data?.data?.searchData?.lng,
            location_address: response?.data?.data?.searchData?.address,
            antenna_height: +response?.data?.data?.searchData?.height,
          });
          commit(
            "SET_SEARCHDATA_FROM_CONNECTIVITY_DETAILS",
            response?.data?.data?.searchData
          );
          commit("SET_GLOBAL_SEARCH_DATA", {
            searchType: { id: 1, text: "New Connectivity Report" },
            coordinates: `${response?.data?.data?.searchData?.lat},${response?.data?.data?.searchData?.lng}`,
            address: {
              id: response?.data?.data?.searchData?.location_id,
              formattedAddress: response?.data?.data?.searchData?.address,
            },
            antennaHeight: response?.data?.data?.searchData?.height,
          });
          commit("SET_LOCATION_DATA", {
            lat: +response?.data?.data?.searchData?.lat,
            lng: +response?.data?.data?.searchData?.lng,
          });

          const data = response?.data?.data?.lookupData;
          const locations: any = [];
          if (data?.length > 0) {
            const filtered = data.filter((item: any) => item?.id == 7)[0];

            filtered?.details.map((tower: any) => {
              locations.push({
                lat: +tower?.tower_lat,
                lng: +tower?.tower_long,
                service: tower?.service,
              });
            });
          }
          // console.log(locations, "c92");

          commit("SET_MARKER_LOCATIONS_DATA", locations);
          commit("SET_CONNECTIVITY_LOOKUP_DATA", {
            details: response?.data?.data?.lookupData,
          });
          commit("SET_CONNECTIVITY_DATA_RESPONSE", {
            details: response?.data?.data,
          });

          commit("SET_PRESERVED_CONNECTIVITY_LOOKUP_DATA", {
            details: response?.data?.data?.lookupData,
          });
          commit("SET_NEWCONNECTIVITYREPORT_COMPONENT_SHOWHIDE", true);
        })
        .catch(() => {
          commit("SET_REGENERATE_CONNECTIVITY_REPORT_DATA_LOADING", false);
          commit("SET_ISLOADING_LOCSEARCH", false);
          commit("SET_ISEMPTY_STATE", false);
        });
    },
    getFormLinkData({commit}: any){
      getFormLinkItems()
        .then((response) => {
          commit ("SET_FORM_LINK_ITEMS", response?.data?.data)
          if(state.isRegistrationForm && response?.data?.data?.length == 0){
            commit("SET_REGISTRATION_FORM_COMPONENT_SHOWHIDE", false);
            commit("SET_MENU_MANAGEMENT_COMPONENT_SHOWHIDE", true);
          }
        })
        .catch((error) => {
          // console.log(error)
        })
    },
    getEmailList({commit}: any, payload: any){
      getEmails(payload)
      .then((response) => {
        commit("SET_EMAILS", response.data.data)
      })
      .catch((error) => {
        // console.log(error)
      })
    },
  },
};
export default locSearch;
