<div class="component-wrap">
    <!-- <AppSearchLoader v-if="isLoading"/> -->
    <AppNoData v-if="!getLookupLOCIDOutput" />
    <div class="component-center h-100">
        <div class="card-wrap-list d-flex justify-content-center align-items-center h-100">
            <div class="app-card w-100">
                <div class="card-header">
                    <p>{{getLookupLOCIDOutput?.premises_technology?getLookupLOCIDOutput?.premises_technology:"NBN Broadband Access planned for your area. Stay tuned."}}</p>
                </div>
                <div class="card-content">
                    <div class="card-lists">
                        <!-- <div class="card-list">
                            <span>Poi name</span>
                            <span>{{ getLookupLOCIDOutput?.poi_name }}</span>
                        </div>
                        <div class="card-list">
                            <span>CSA Name</span>
                            <span>{{ getLookupLOCIDOutput?.csa_name }}</span>
                        </div> -->
                        <div class="card-list">
                            <span>Premises Locations ID</span>
                            <span>{{ getLookupLOCIDOutput?.premises_locations_id }}</span>
                        </div>
                        <div v-if="getLookupLOCIDOutput?.message" class="card-list">
                            <span>Message</span>
                            <span>{{ getLookupLOCIDOutput?.message }}</span>
                        </div>
                        <div v-if="!getLookupLOCIDOutput?.message" >
                            <div class="card-list">
                                <span>Premises Technology</span>
                                <span>{{ getLookupLOCIDOutput?.premises_technology }}</span>
                            </div>
                            <div class="card-list">
                                <span>Premises Service Class</span>
                                <span>{{ getLookupLOCIDOutput?.premises_service_class }}</span>
                            </div>
                            <div class="card-list">
                                <span>Premises Service Class Description</span>
                                <span>{{ getLookupLOCIDOutput?.premises_service_description }}</span>
                            </div>
                            <div class="card-list">
                                <span>Premises Readiness</span>
                                <span>{{ getLookupLOCIDOutput?.premises_readiness }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-footer">
                    <k-button @click="closeGotoRecent" :theme-color="'dark'">Close</k-button>
                </div>
            </div>
        </div>
    </div>

</div>