<template>
    <AppModal :close="close" :modal-title="'User Details'" :modal-show="show">
        <template v-slot:content="">
            <div class="add-address-modal">
                <div class="head mb-3">
                    <p class="font-s-16 text-actions-dark">Please enter your details below to generate your report. We'll
                        need your first name, last name, email and phone number to get started.</p>
                </div>
                <div class="content">
                    <k-form>
                        <form-element>
                            <div class="row">
                                <div class="col-md-12">
                                    <field-wrapper>
                                        <k-label>First Name</k-label>
                                        <div class="k-form-field-wrap">
                                            <k-input @keypress="this.errorMessage['first_name'] = ''"
                                                v-model="form.first_name" />
                                            <k-error class="form-alert-position">{{ this.errorMessage['first_name']
                                            }}</k-error>
                                        </div>
                                    </field-wrapper>
                                </div>
                                <div class="col-md-12">
                                    <field-wrapper>
                                        <k-label>Last Name</k-label>
                                        <div class="k-form-field-wrap">
                                            <k-input @keypress="this.errorMessage['last_name'] = ''"
                                                v-model="form.last_name" />
                                            <k-error class="form-alert-position">{{ this.errorMessage['last_name']
                                            }}</k-error>
                                        </div>
                                    </field-wrapper>
                                </div>
                                <div class="col-md-12">
                                    <field-wrapper>
                                        <k-label>Email</k-label>
                                        <div class="k-form-field-wrap">
                                            <k-input @keypress="this.errorMessage['email'] = ''" v-model="form.email" />
                                            <k-error class="form-alert-position">{{ this.errorMessage['email']
                                            }}</k-error>
                                        </div>
                                    </field-wrapper>
                                </div>
                                <div class="col-md-12">
                                    <field-wrapper>
                                        <k-label>Phone</k-label>
                                        <div class="k-form-field-wrap">
                                            <k-input @keypress="this.errorMessage['phone'] = ''" v-model="form.phone" />
                                            <k-error class="form-alert-position">{{ this.errorMessage['phone']
                                            }}</k-error>
                                        </div>
                                    </field-wrapper>
                                </div>

                            </div>
                        </form-element>
                    </k-form>
                </div>
            </div>
        </template>
        <template v-slot:footer="">
            <k-button :theme-color="'dark'" @click="close">Cancel</k-button>
            <k-button @click="onSubmit" :theme-color="'primary'">Submit</k-button>
        </template>
    </AppModal>
</template>
  
<script>
import { Input, TextArea } from '@progress/kendo-vue-inputs'
import AppModal from "@/components/commons/app-modal/AppModal";
import { FieldWrapper, Form, FormElement } from "@progress/kendo-vue-form";
import { Error } from "@progress/kendo-vue-labels/dist/esm/main";
import { Label } from "@progress/kendo-vue-labels";
import { Button } from "@progress/kendo-vue-buttons";

export default {
    name: "userDetailsModal",
    components: {
        AppModal,
        'k-button': Button,
        'k-textarea': TextArea,
        'k-form': Form,
        'field-wrapper': FieldWrapper,
        "form-element": FormElement,
        'k-error': Error,
        'k-input': Input,
        'k-label': Label,
    },
    mounted() {
    },
    props: {
        show: {
            type: Boolean,
            default: false
        },
        close: {
            type: Function,
            default: null
        },
        submit: {
            type: Function,
            default: null
        }

    },
    data() {
        return {
            form: {
                first_name: '',
                last_name: '',
                email: '',
                phone: '',
            },
            errorMessage: {
                first_name: '',
                last_name: '',
                email: '',
                phone: '',
            }
        }
    },
    methods: {
        onSubmit() {
            if (this.dataValidation()) {
                this.submit(this.form)
            }
        },
        dataValidation() {
            let isValid = true;
            if (this.form.firstName == '') {
                this.errorMessage.first_name = 'First Name is required';
                isValid = false;
            } else {
                this.errorMessage.first_name = '';
            }
            if (this.form.lastName == '') {
                this.errorMessage.last_name = 'Last Name is required';
                isValid = false;
            } else {
                this.errorMessage.last_name = '';
            }
            if (this.form.email == '') {
                this.errorMessage.email = 'Email is required';
                isValid = false;
            } else {
                this.errorMessage.email = '';
            }
            if (this.form.phone == '') {
                this.errorMessage.phone = 'Phone is required';
                isValid = false;
            } else {
                this.errorMessage.phone = '';
            }
            if (this.form.email != '') {
                let emailRegex = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/;
                if (!emailRegex.test(this.form.email)) {
                    this.errorMessage.email = 'Please enter valid email';
                    isValid = false;
                } else {
                    this.errorMessage.email = '';
                }
            }
            return isValid;
        }
    }
}
</script>
  
<style scoped>
.k-dialog-wrapper.modal-small .k-dialog {
    width: 500px !important;
    min-height: auto !important;
    max-height: 100vh !important;
}</style>
  