<template>
    <GoogleMap v-if="markerLocationsData?.length" id="map" ref="mapRef" style="width: 100%; height: 500px"
        :api-key="map.key" :center="locationData" mapTypeId='satellite' :zoom="map.zoom" :min-zoom="map.minZoom"
        :max-zoom="map.maxZoom" :street-view-control="map.streetViewControl" @zoom_changed="zoomChanged"
        @center_changed="centerChanged">
        <MarkerCluster>
            <Marker v-for="(location, index) in markerLocationsData" :options="{ position: location, icon: generateColor(location, icon) }"
                :key="index" />
            <Marker
                :options="{ position: gpsCoordinatesValue, icon: 'https://img.icons8.com/color/48/null/user-location.png' }"
                :key="index" />
        </MarkerCluster>
    </GoogleMap>
</template>
  
<script>
import { GoogleMap, Marker, MarkerCluster } from 'vue3-google-map';
import IconTower from "@/components/svg-icons/IconTower";
import { configuration } from '@/configurationProvider';
export default {
    name: "googleMapPreview",
    components: {
        GoogleMap,
        Marker,
        MarkerCluster,
        IconTower
    },
    props: {
    },
    data() {
        return {
            icon: {
                path: "M27.648-41.399q0-3.816-2.7-6.516t-6.516-2.7-6.516 2.7-2.7 6.516 2.7 6.516 6.516 2.7 6.516-2.7 2.7-6.516zm9.216 0q0 3.924-1.188 6.444l-13.104 27.864q-.576 1.188-1.71 1.872t-2.43.684-2.43-.684-1.674-1.872l-13.14-27.864q-1.188-2.52-1.188-6.444 0-7.632 5.4-13.032t13.032-5.4 13.032 5.4 5.4 13.032z",
                fillColor: '#E32831',
                fillOpacity: 1,
                strokeWeight: 0,
                scale: 0.65
            },
            map: {
                key: configuration?.google_map_API_key, // Place a Google API Key here
                // zoom: 10.7,
                zoom: 11,
                minZoom: 2,
                maxZoom: null,
                streetViewControl: false
            },
        }
    },
    computed: {
        locationData() {
            return this.$store.state.locSearch.locationData;
        },
        markerLocationsData() {
            return this.$store.state.locSearch.markerLocationsData;
        },
        gpsCoordinatesValue() {
            return {
                lat: Number(this.$store.state.locSearch.globalSearchData?.coordinates?.split(',')[0]),
                lng: Number(this.$store.state.locSearch.globalSearchData?.coordinates?.split(',')[1]),
            };
        },
    },
    watch: {

    },
    methods: {
        generateColor(location, icon){
            if(location?.service === "Telstra"){
                return "https://img.icons8.com/ios-filled/50/0000FF/marker.png"
            }
            else if(location?.service === "Optus"){
                return "https://img.icons8.com/ios-filled/50/FFFF00/marker.png"
            }
            else if(location?.service === "TPG"){
                return "https://img.icons8.com/ios-filled/50/3cb043/marker.png"
            }
            else if(location?.service === "Vodafone"){
                return "https://img.icons8.com/ios-filled/50/FA5252/marker.png"
            }else{
                return "https://img.icons8.com/stickers/50/000000/region-code.png"
            }
        }
    }
}
</script>
  
<style scoped></style>
  