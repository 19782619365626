<template>
    <AppModal :close="close" :modal-title="'Email PDF'" :modal-show="show">
        <template v-slot:content="">
            <div class="add-address-modal">
                <div class="head mb-3">
                    <p class="font-s-16 text-actions-dark">Type the email address of the recipient of the pdf</p>
                </div>
                <div class="content">
                    <k-form>
                        <form-element>
                            <div class="row">
                                <div class="col-md-12">
                                    <field-wrapper>
                                        <k-label>Email</k-label>
                                        <div class="k-form-field-wrap">
                                            <k-input @keypress="this.errorMessage['email'] = ''" v-model="form.email" />
                                            <k-error class="form-alert-position">{{ this.errorMessage['email']
                                            }}</k-error>
                                        </div>
                                    </field-wrapper>
                                </div>

                            </div>
                        </form-element>
                    </k-form>
                </div>
            </div>
        </template>
        <template v-slot:footer="">
            <k-button :theme-color="'dark'" @click="close">Cancel</k-button>
            <k-button @click="onSubmit" :theme-color="'primary'">Send</k-button>
        </template>
    </AppModal>
</template>
  
<script>
import { Input, TextArea } from '@progress/kendo-vue-inputs'
import AppModal from "@/components/commons/app-modal/AppModal";
import { FieldWrapper, Form, FormElement } from "@progress/kendo-vue-form";
import { Error } from "@progress/kendo-vue-labels/dist/esm/main";
import { Label } from "@progress/kendo-vue-labels";
import { Button } from "@progress/kendo-vue-buttons";
import { sendEmailPDF } from '@/services/LocSeach.service';

export default {
    name: "sendEmailModal",
    components: {
        AppModal,
        'k-button': Button,
        'k-textarea': TextArea,
        'k-form': Form,
        'field-wrapper': FieldWrapper,
        "form-element": FormElement,
        'k-error': Error,
        'k-input': Input,
        'k-label': Label,
    },
    mounted() {
    },
    props: {
        show: {
        type: Boolean,
        default: false,
        },
        close: {
        type: Function,
        default: null,
        },
        path: {
            type: String,
            default: ""
        }
    },
    data() {
        return {
            form: {
                email: '',
            },
            errorMessage: {
                email: '',
            }
        }
    },
    methods: {
        onSubmit() {
            if(this.form.email){
                let isValid = true;
                let emailRegex = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/;
                if (!emailRegex.test(this.form.email)) {
                    this.errorMessage.email = 'Please enter valid email';
                    isValid = false;
                } else {
                    this.errorMessage.email = '';
                    isValid = true
                }

                if(!isValid){
                    return
                }else{
                    let payload = {
                        email: this.form.email,
                        file_path: this.path
                    }
                    sendEmailPDF(payload)
                    .then((response) => {
                        if(response?.status == 200){
                            this.$store.commit("SET_SHOW_EMAIL_MODAL", false);
                            this.$store.commit("SET_NOTIFICATION_DATA", {
                                type: "success",
                                message: "Email sent successfully",
                                visibility: true,
                            });
                            this.$store.dispatch("getEmailList");
                        }
                    })
                    .catch((error) => {
                        this.$store.commit("SET_NOTIFICATION_DATA", {
                            type: "error",
                            message: "Email want not sent",
                            visibility: true,
                        });
                    })
                }
            }else{
                this.errorMessage.email = "Email can't be empty";
                return
            }
            }
    }
}
</script>
  
<style scoped></style>
  