<template src="./NewTechnicalLookup.html"></template>

<script>
import { Button } from "@progress/kendo-vue-buttons";
import { TextArea } from "@progress/kendo-vue-inputs";
import IconQuestion from "@/components/svg-icons/IconQuestion";
import IconGraph from "@/components/svg-icons/IconGraph";
import IconFile from "@/components/svg-icons/IconFile";
import IconTower from "@/components/svg-icons/IconTower";
import {
  Chart,
  ChartTitle,
  ChartSeries,
  ChartSeriesItem,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartValueAxis,
  ChartValueAxisItem,
} from "@progress/kendo-vue-charts";
import googleMapPreview from "@/components/commons/google-map-preview/googleMapPreview.vue";
import towerInfoModal from "@/components/commons/modals/tower-info-modal/towerInfoModal.vue";

import { randomStringGenerator } from "@/helpers/utility";


export default {
  name: "NewTechnicalLookup",
  components: {
    'k-button': Button,
    'k-textarea': TextArea,
    IconQuestion,
    IconGraph,
    IconFile,
    IconTower,
    Chart,
    ChartTitle,
    ChartSeries,
    ChartSeriesItem,
    ChartCategoryAxis,
    ChartCategoryAxisItem,
    ChartValueAxis,
    ChartValueAxisItem,
    googleMapPreview,
    towerInfoModal,

  },
  data() {
    return {
      selectedCard: false,

      selectedTowerIndex: 0,
      selecteBroadbandIndex: 0,
      selectedTowerInfo: null,
      towerInfoModalVisibility: false,
      selectedWispTowerIndex: 0
    }
  },
  computed: {
    getTechnicalLookupOutput() {
      return this.$store.state.locSearch.getTechnicalLookupOutput;
    },
    markerLocationsData() {
      return this.$store.state.locSearch.markerLocationsData;
    },
    appSearchData() {
      return this.$store.state.locSearch.appSearchData;
    },
  },
  mounted() {
    this.$store.commit("SET_ISEMPTY_STATE", false);
  },
  methods: {
    seriesFunc(item) {
      let item2 = JSON.parse(JSON.stringify(item));
      item2?.forEach((element, index) => {
        if (index !== 0 && index !== (item2?.length - 1)) {
          item2[index] = null;
        }
      });
      return item2;
    },
    switchToConnectivityReportHandler() {
      this.$store.dispatch("getConnectivityLookupOutput", {
        ...this.appSearchData,
      });
      this.$store.commit("SET_GLOBAL_SEARCH_DATA", {
        searchType: { id: 1, text: "New Connectivity Report" },
      });
    },
    closeTowerInfoModal() {
      this.towerInfoModalVisibility = false;
    },
    chartSeeMoreHandler(towerData) {
      this.selectedTowerInfo = towerData;
      this.towerInfoModalVisibility = true;
    },
    switchTowerHandler(index) {
      this.selectedTowerIndex = index;
    },
    switchWispTowerHandler(index) {
      this.selectedWispTowerIndex = index;
    },
    switchBroadbandHandler(index) {
      this.selecteBroadbandIndex = index;
    },
    selectedCardHandler() {
      this.selectedCard = !this.selectedCard
    },
    closeGotoRecent() {
      this.$store.commit("SET_GLOBAL_SEARCH_DATA", {
        searchType: null,
        address: {
          id: "",
          formattedAddress: ""
        },
        coordinates: "",
        antennaHeight: "",
      });
      this.$store.dispatch("getConnectivityReportsData", {});
      this.$store.commit("SET_ISEMPTY_STATE", false);
      this.$store.commit("SET_LookupLOCID_COMPONENT_SHOWHIDE", false);
      this.$store.commit("SET_LOOKUPFWRANGE_COMPONENT_SHOWHIDE", false);
      this.$store.commit("SET_NEWCONNECTIVITYREPORT_COMPONENT_SHOWHIDE", false);
      this.$store.commit("SET_NEWTECHNICALLOOKUP_COMPONENT_SHOWHIDE", false);
      this.$store.commit("SET_NEWSITELOOKUP_COMPONENT_SHOWHIDE", false);
      this.$store.commit('SET_RECENTSEARCH_COMPONENT_SHOWHIDE', true);
      this.$store.commit("SET_MENU_MANAGEMENT_COMPONENT_SHOWHIDE", false);
      this.$store.commit("SET_REGISTRATION_FORM_COMPONENT_SHOWHIDE", false);
    }
  }
}
</script>

<style scoped></style>