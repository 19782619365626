<div class="component-wrap">
    <div class="component-center">
        <div class="card-wrap">
            <div class="app-card" :class="selectedCard ? 'selected' : ''" v-for="single in getNewSiteLookupOutput">
                <div class="card-header" >
                    <p>{{single.service_name}}</p>
                    <k-button @click="selectedCardHandler" :class="'btn-select'" :theme-color="'primary'" :fill-mode="selectedCard ? 'solid' : 'outline'" :icon="selectedCard ? 'check' : 'plus'">Selected</k-button>
                </div>
                <div class="card-content">
                    <div class="card-box">
                        <div class="card-left-icon">
                            <IconQuestion />
                        </div>
                        <div class="card-detail">
                            <div v-if="single?.details.length === 1">
                                <h3>{{single?.title}}</h3>
                                <p>{{ single?.details[0] }}</p>
                            </div>
                            <div class="row" v-else>
                                <div class="col-md-6">
                                    <h3 v-if="single.title">{{single.title}}</h3>
                                    <ul>
                                        <li v-for="i in single?.details" :key="index">
                                            <a href="#">
                                                <span class="icon">
                                                    <IconTower />
                                                </span>
                                                <span class="text">
                                                   {{i}}
                                                </span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div class="col-md-6" v-if="single?.chartData">
                                    <div class="chart-box">
                                        <Chart>
                                            <ChartTitle
                                                    :text="'Blighty Hall Road - Blighty (-35.37489,145.38447 - 5.2km @ 194 Degrees)'"
                                                    :position="'bottom'"
                                                    :align="'center'"
                                            />
                                            <ChartCategoryAxis>
                                              <ChartCategoryAxisItem
                                              />
                                            </ChartCategoryAxis>
                                            <ChartSeries>
                                              <ChartSeriesItem
                                                :type="'area'"
                                                :data-items="single?.chartData"
                                              />

                                            </ChartSeries>
                                          </Chart>
                                    </div>
                                </div>
                                <div class="col-md-6" v-if="single?.imageData === ''" >
                                    <div class="chart-box">
                                        <img src="~@/assets/images/map-img.png" alt="map">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="app-card">
                <div class="card-content">
                    <div class="add-card">
                        <h3>Technical Summary</h3>
                        <div class="card-form">
                            <k-textarea :rows="4" :value="'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.'"/>
                        </div>
                    </div>
                </div>

                <div class="card-footer">
                    <div class="d-flex justify-content-between">
                        <div>
                            <k-button  @click="closeGotoRecent" :theme-color="'dark'">Cancel</k-button>
                        </div>
                        <div>
                            <k-button :class="'ms-auto me-3'" :theme-color="'dark'">Edit & Submit</k-button>
                            <k-button :theme-color="'primary'">Submit</k-button>
                        </div>
                    </div>
                </div>

            </div>

        </div>

    </div>

</div>