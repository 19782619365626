<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.6832 17L15.7714 17C16.2395 17 16.6883 16.8156 17.0193 16.4874C17.3502 16.1592 17.5361 15.7141 17.5361 15.25L17.5361 4.75C17.5361 4.28587 17.3502 3.84075 17.0193 3.51256C16.6883 3.18437 16.2395 3 15.7714 3L12.6832 3M12.4639 10L2.46387 10M2.46387 10L6.28483 14M2.46387 10L6.28483 6" stroke="#1F2426" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "IconLogout"
}
</script>

<style scoped>

</style>