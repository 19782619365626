<template>
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.3333 3.66667H3C2.46957 3.66667 1.96086 3.87738 1.58579 4.25245C1.21071 4.62753 1 5.13623 1 5.66667V15C1 15.5304 1.21071 16.0391 1.58579 16.4142C1.96086 16.7893 2.46957 17 3 17H12.3333C12.8638 17 13.3725 16.7893 13.7475 16.4142C14.1226 16.0391 14.3333 15.5304 14.3333 15V7.66667M5 13L17 1M17 1H12.3333M17 1V5.66667"
      stroke=""
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "IconRegister",
};
</script>