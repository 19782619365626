<div class="component-wrap">
    <div class="component-center">
        <div class="search-recent-wrap">
            <div class="search-recent-head">
                <h3>Recent searches</h3>
            </div>
            <div class="search-recent-grid">
                <grid :class="'recent-search-table-wrap'" :data-items="getConnectivityReportsData" :columns="columns" :skip="skip" :take="take"
                    :total="total" :pageable="gridPageable" @pagechange="pageChangeHandler">
                    <template v-slot:actionsTemplate="{ props }">
                        <td>
                            <k-button @click="regenerateHandler(props.dataItem.id)" :fill-mode="'flat'"
                                :theme-color="'primary'">Regenerate</k-button>
                        </td>
                    </template>
                </grid>
            </div>
        </div>
    </div>
</div>