<template src="./LookupLOCID.html"></template>

<script>
import AppNoData from "@/components/commons/app-no-data/AppNoData.vue";
import AppSearchLoader from "@/components/commons/app-search-loader/AppSearchLoader.vue";
import { Button } from "@progress/kendo-vue-buttons/dist/esm/main";

export default {
  name: "LookupLOCID",
  components: {
    AppNoData,
    AppSearchLoader,
    'k-button': Button
  },
  data: function () {
    return {

    }
  },
  computed: {
    getLookupLOCIDOutput() {
      return this.$store.state.locSearch.locationLocIdOutput;
    },
    isLookupLOCID() {
      return this.$store.state.locSearch.isLookupLOCID;
    },
    isLoading() {
      return this.$store.state.locSearch.isLoading;
    },
  },
  methods: {
    closeGotoRecent() {
      this.$store.commit("SET_GLOBAL_SEARCH_DATA", {
        searchType: null,
        address: {
          id: "",
          formattedAddress: ""
        },
        coordinates: "",
        antennaHeight: "",
      });
      this.$store.dispatch("getConnectivityReportsData", {});
      this.$store.commit("SET_ISEMPTY_STATE", false);
      this.$store.commit("SET_LookupLOCID_COMPONENT_SHOWHIDE", false);
      this.$store.commit("SET_LOOKUPFWRANGE_COMPONENT_SHOWHIDE", false);
      this.$store.commit("SET_NEWCONNECTIVITYREPORT_COMPONENT_SHOWHIDE", false);
      this.$store.commit("SET_NEWTECHNICALLOOKUP_COMPONENT_SHOWHIDE", false);
      this.$store.commit("SET_NEWSITELOOKUP_COMPONENT_SHOWHIDE", false);
      this.$store.commit('SET_RECENTSEARCH_COMPONENT_SHOWHIDE', true);
      this.$store.commit("SET_MENU_MANAGEMENT_COMPONENT_SHOWHIDE", false);
      this.$store.commit("SET_REGISTRATION_FORM_COMPONENT_SHOWHIDE", false);
    }
  },
}
</script>

<style scoped></style>