<template src="./ConrepOutput.html"></template>

<script>
import { Button } from "@progress/kendo-vue-buttons/dist/esm/main";
import IconDoc from "@/components/svg-icons/IconDoc";
import IconPDF from "@/components/svg-icons/IconPDF";
import { configuration } from "@/configurationProvider";
import sendEmailModal from '@/components/commons/modals/send-email-modal/sendEmailModal.vue';
import axios from "axios";
import path from 'path';
export default {
  name: "ConrepOutput",
  components: {
    'k-button': Button,
    IconDoc,
    IconPDF,
    sendEmailModal,
  },
  data() {
    return {
      // showEmailModal: false,
    };
  },
  computed: {
    conRepOutputData() {
      return this.$store.state.locSearch.conRepOutputData;
    },
    showEmailModal(){
      return this.$store.state.locSearch.showEmailModal;
    }
  },
  methods: {
    gotoRecent() {
      this.$store.commit("SET_GLOBAL_SEARCH_DATA", {
        searchType: null,
        address: {
          id: "",
          formattedAddress: ""
        },
        coordinates: "",
        antennaHeight: "",
      });
      this.$store.dispatch("getConnectivityReportsData", {});
      this.$store.commit("SET_NEWCONNECTIVITYREPORT_COMPONENT_SHOWHIDE", false);
    },
    generateLink(path) {
      return configuration.apiBaseUrl + '/api/v1/connectivity/pdf/download' + `?file_path=${path}`;
    },
    getFileExtension(filename) {
      return path.extname(filename).toLowerCase();
    },
    downloadFile(path) {
      let token = null;
      token = localStorage.getItem("rth_session");
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      this.$store.commit('SET_ISGLOBALLOADING_LOCSEARCH', true)
      axios({
        url: this.generateLink(path),
        method: 'GET',
        headers: headers,
        responseType: 'blob',
      }).then((response) => {
        this.$store.commit('SET_ISGLOBALLOADING_LOCSEARCH', false)
        let fileURL = window.URL.createObjectURL(new Blob([response.data]));
        let fileLink = document.createElement("a");
        fileLink.href = fileURL;
        const fileExtention = this.getFileExtension(path);
        let filename =  `${this.conRepOutputData?.pdf_name}`+fileExtention;
        fileLink.setAttribute("download", filename);
        document.body.appendChild(fileLink);
        fileLink.click();
        this.$store.commit("SET_NOTIFICATION_DATA", {
          type: "success",
          message: "Report downloaded successfully",
          visibility: true,
        });
      }).catch((error) => {
        this.$store.commit('SET_ISGLOBALLOADING_LOCSEARCH', false)
        this.$store.commit("SET_NOTIFICATION_DATA", {
          type: "error",
          message: "Error while downloading report",
          visibility: true,
        });
      });
    },
    setShowEmailModal() {
      this.$store.commit("SET_SHOW_EMAIL_MODAL", true)
    },
    closeEmailModal() {
      this.$store.commit("SET_SHOW_EMAIL_MODAL", false)
    },
  },
}
</script>

<style scoped></style>