<template>
  <svg width="26" height="30" viewBox="0 0 26 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.35366 18.1191H5.12988V19.9443H6.35366C6.84493 19.9443 7.23371 19.5115 7.23371 19.0569C7.23269 18.5446 6.84494 18.1191 6.35366 18.1191Z" fill="#5A5C60"/>
    <path d="M11.2789 18.1562H10.0625V21.4397H11.2789C13.2579 21.4399 13.3451 18.1562 11.2789 18.1562Z" fill="#5A5C60"/>
    <path d="M21.2498 14.084H1.08317C0.579004 14.084 0.166504 14.4965 0.166504 15.0007V24.1673C0.166504 24.6715 0.579004 25.084 1.08317 25.084H21.2498C21.7548 25.084 22.1665 24.6715 22.1665 24.1673V15.0007C22.1665 14.4965 21.7548 14.084 21.2498 14.084ZM6.35374 20.861H5.12997V21.829C5.12997 22.1515 4.92474 22.334 4.66059 22.334C4.41853 22.334 4.16276 22.1515 4.16276 21.828V17.7022C4.16276 17.4601 4.35346 17.1953 4.66059 17.1953L6.35375 17.1951C7.30703 17.1951 8.16498 17.8331 8.16498 19.0569C8.16416 20.2156 7.30703 20.861 6.35375 20.861L6.35374 20.861ZM11.3451 22.334H9.60073C9.35868 22.334 9.09472 22.201 9.09472 21.8794V17.7167C9.09472 17.4528 9.35868 17.2621 9.60073 17.2621H11.2792C14.6285 17.2621 14.5552 22.334 11.3451 22.334ZM17.8765 18.2145H15.7426V19.3796H17.6117C17.8767 19.3796 18.1396 19.6435 18.1396 19.9003C18.1396 20.1424 17.8765 20.3394 17.6117 20.3394H15.7426V21.8785C15.7426 22.1351 15.56 22.3332 15.3035 22.3332C14.9808 22.3332 14.7827 22.1351 14.7827 21.8785V17.7159C14.7827 17.4519 14.9816 17.2612 15.3035 17.2612H17.8765C18.1991 17.2612 18.3888 17.4519 18.3888 17.7159C18.3888 17.9514 18.1991 18.2145 17.8765 18.2145Z" fill="#5A5C60"/>
    <path d="M24.9161 7.28756L18.8792 1.25065C18.3493 0.719089 17.4181 0.333984 16.6663 0.333984H6.58301C5.06684 0.333984 3.83301 1.56782 3.83301 3.08398V12.2507C3.83301 12.7575 4.24285 13.1673 4.74967 13.1673C5.2565 13.1673 5.66634 12.7575 5.66634 12.2507V3.08398C5.66634 2.579 6.077 2.16732 6.58301 2.16732H14.833C15.8458 2.16732 16.6663 2.98865 16.6663 4.00065V7.43815C16.6663 8.57293 17.5959 9.50065 18.7288 9.50065H22.1663C23.1792 9.50065 23.9997 10.322 23.9997 11.334V26.9173C23.9997 27.4223 23.5898 27.834 23.083 27.834H6.58301C6.077 27.834 5.66634 27.4223 5.66634 26.9173C5.66634 26.4105 5.2565 26.0007 4.74967 26.0007C4.24285 26.0007 3.83301 26.4105 3.83301 26.9173C3.83301 28.4335 5.06684 29.6673 6.58301 29.6673H23.083C24.5992 29.6673 25.833 28.4335 25.833 26.9173V9.50065C25.833 8.74993 25.4479 7.81854 24.9163 7.28777L24.9161 7.28756Z" fill="#5A5C60"/>
  </svg>
</template>

<script>
export default {
  name: "IconPDF"
}
</script>

<style scoped>

</style>