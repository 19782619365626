<div class="component-wrap">
    <div class="component-center">
        <div class="card-wrap">
            <div v-if="!isEditModeEnabled" v-for="(connectivityData, index) in connectivityLookupData?.details">
                <div v-if="connectivityData?.id == 0" class="app-card border-bottom-none">
                    <div class="card-content">
                        <div class="card-box">
                            <div class="card-left-icon">
                                <IconPage />
                            </div>
                            <div class="card-detail">
                                <div>
                                    <h3>{{connectivityData?.title}}</h3>
                                    <p v-if="connectivityData?.sub_title">{{ connectivityData?.sub_title }}
                                    </p>
                                </div>
                                <div class="card-lists">
                                    <!-- <div class="card-list">
                                        <span>Poi name</span>
                                        <span>{{connectivityData?.details?.poi_name}}</span>
                                    </div>
                                    <div class="card-list">
                                        <span>CSA Name</span>
                                        <span>{{connectivityData?.details?.csa_name}}</span>
                                    </div> -->
                                    <div v-if="connectivityData?.details?.message" class="card-list">
                                        <span>Message</span>
                                        <span><strong>{{connectivityData?.details?.message}}</strong></span>
                                    </div>
                                    <div v-if="!connectivityData?.details?.message">
                                        <div class="card-list">
                                            <span>Premises Locations ID</span>
                                            <span>{{connectivityData?.details?.premises_locations_id}}</span>
                                        </div>
                                        <div class="card-list">
                                            <span>Premises Technology</span>
                                            <span>{{connectivityData?.details?.premises_technology}}</span>
                                        </div>
                                        <div class="card-list">
                                            <span>Premises Service Class</span>
                                            <span>{{connectivityData?.details?.premises_service_class}}</span>
                                        </div>
                                        <div class="card-list">
                                            <span>Premises Service Class Description</span>
                                            <span>{{connectivityData?.details?.premises_service_description}}</span>
                                        </div>
                                        <div class="card-list">
                                            <span>Premises Readiness</span>
                                            <span>{{connectivityData?.details?.premises_readiness}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div v-if="connectivityData?.id == 1" class="app-card"
                    :class="connectivityData?.is_selected ? 'selected' : ''">
                    <div class="card-header border-bottom">
                        <p>{{connectivityData?.title}}</p>
                        <k-button @click="selectedCardHandler(index)" :class="'btn-select'" :theme-color="'primary'"
                            :fill-mode="connectivityData?.is_selected ? 'solid' : 'outline'"
                            :icon="connectivityData?.is_selected ? 'check' : 'plus'">{{connectivityData?.is_selected ?
                            'Selected' : 'Select'}}</k-button>
                    </div>

                    <div class="row">
                        <div
                            :class="[4].includes(connectivityData?.id) && connectivityData?.details?.chartData?.length>0 ? 'col-md-6' : ''">
                            <div class="card-content border-0">
                                <div class="card-box">
                                    <div class="card-left-icon">
                                        <IconQuestion />
                                    </div>
                                    <div class="card-detail">
                                        <h3>{{connectivityData?.services?.[0]?.title}}</h3>
                                        <p> {{connectivityData?.services?.[0]?.details}}
                                        </p>

                                        <div v-if="[4].includes(connectivityData?.id)"
                                            class="max-h-480 overflow-y-auto">
                                            <ul>
                                                <li v-for="(data, index) in connectivityData?.details?.towers"
                                                    :key="index">
                                                    <a :class="selectedWispTowerIndex == index ? 'active' : '' "
                                                        @click="switchWispTowerHandler(index)" href="#">
                                                        <span class="icon">
                                                            <IconTower />
                                                        </span>
                                                        <span class="text">
                                                            {{data}}
                                                        </span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div v-if="[4].includes(connectivityData?.id) && connectivityData?.details?.chartData?.length>0"
                            class="col-md-6">
                            <div class="py-4">

                                <div class="chart-box">
                                    <Chart>
                                        <ChartCategoryAxis>
                                            <ChartCategoryAxisItem />
                                        </ChartCategoryAxis>
                                        <ChartSeries>
                                            <ChartSeriesItem :markers="false"
                                                :data-items="seriesFunc(connectivityData?.details?.chartData[selectedWispTowerIndex])"
                                                :type="'line'" :style="{ strokeWidth: 3 }" />
                                            <ChartSeriesItem :type="'area'"
                                                :data-items="connectivityData?.details?.chartData[selectedWispTowerIndex]" />
                                        </ChartSeries>
                                    </Chart>
                                    <!-- <div class="text-center">
                                        <p
                                            class="max-w-408 font-s-13 font-w-normal line-h-1_4 ms-auto me-auto mb-0">
                                            {{connectivityData?.details[selectedTowerIndex]?.chartTitle}}</p>
                                    </div> -->
                                    <!-- <div class="text-center">
                                        <k-button
                                            @click="chartSeeMoreHandler(connectivityData?.details[selectedTowerIndex])"
                                            :fill-mode="'flat'" :class="'btn-text-blue'">See More</k-button>
                                    </div> -->
                                </div>
                            </div>
                        </div>
                    </div>




                </div>
                
                <div v-if="connectivityData?.id == 2" class="app-card"
                    :class="connectivityData?.is_selected ? 'selected' : ''">
                    <div class="card-header">
                        <p>{{connectivityData?.title}}</p>
                        <k-button @click="selectedCardHandler(index)" :class="'btn-select'" :theme-color="'primary'"
                            :fill-mode="connectivityData?.is_selected ? 'solid' : 'outline'"
                            :icon="connectivityData?.is_selected ? 'check' : 'plus'">{{connectivityData?.is_selected ?
                            'Selected' : 'Select'}}</k-button>
                    </div>

                    <div class="card-content">
                        <div class="card-box">
                            <div class="card-left-icon">
                                <IconQuestion />
                            </div>
                            <div class="card-detail">
                                <div class="row">
                                    <div class="col-md-6">
                                        <h3 v-if="connectivityData?.messages && connectivityData?.messages.length > 0">
                                            <strong>{{connectivityData?.messages}}</strong>
                                        </h3>
                                        <h3 v-else>{{connectivityData?.sub_title}}</h3>
                                        <div class="max-h-480 overflow-y-auto">
                                            <ul>
                                                <li v-for="(data, index) in connectivityData?.details" :key="index">
                                                    <a :class="selectedTowerIndex == index ? 'active' : '' "
                                                        @click="switchTowerHandler(index)" href="#">
                                                        <span class="icon">
                                                            <IconTower />
                                                        </span>

                                                        <span class="text">
                                                            {{data?.outcome}}
                                                        </span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div v-if="connectivityData?.details[selectedTowerIndex]?.chartData"
                                        class="col-md-6">
                                        <div class="chart-box">
                                            <Chart ref="exportChart">
                                                <ChartValueAxis>
                                                    <ChartValueAxisItem :major-unit="'50'" />
                                                </ChartValueAxis>
                                                <ChartCategoryAxis>
                                                    <ChartCategoryAxisItem />
                                                </ChartCategoryAxis>
                                                <ChartSeries>
                                                    <ChartSeriesItem :markers="false"
                                                        :data-items="seriesFunc(connectivityData?.details[selectedTowerIndex]?.chartData)"
                                                        :type="'line'" :style="{ strokeWidth: 3 }" />
                                                    <ChartSeriesItem :type="'area'"
                                                        :data-items="connectivityData?.details[selectedTowerIndex]?.chartData" />
                                                </ChartSeries>
                                            </Chart>
                                            <div class="text-center">
                                                <p
                                                    class="max-w-408 font-s-13 font-w-normal line-h-1_4 ms-auto me-auto mb-0">
                                                    {{connectivityData?.details[selectedTowerIndex]?.chartTitle}}</p>
                                            </div>
                                            <div class="text-center">
                                                <k-button
                                                    @click="chartSeeMoreHandler(connectivityData?.details[selectedTowerIndex])"
                                                    :fill-mode="'flat'" :class="'btn-text-blue'">See More</k-button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card-content">
                        <div class="card-box">
                            <div class="card-left-icon">
                                <IconQuestion />
                            </div>
                            <div class="card-detail">
                                <h3>{{connectivityData?.services?.[0]?.title}}</h3>
                                <p> {{connectivityData?.services?.[0]?.details}}
                                </p>
                            </div>
                        </div>
                    </div>

                </div>
                <div v-if="[3,4,5,6].includes(connectivityData?.id)" class="app-card"
                    :class="connectivityData?.is_selected ? 'selected' : ''">
                    <div class="card-header border-bottom">
                        <p>{{connectivityData?.title}}</p>
                        <k-button @click="selectedCardHandler(index)" :class="'btn-select'" :theme-color="'primary'"
                            :fill-mode="connectivityData?.is_selected ? 'solid' : 'outline'"
                            :icon="connectivityData?.is_selected ? 'check' : 'plus'">{{connectivityData?.is_selected ?
                            'Selected' : 'Select'}}</k-button>
                    </div>

                    <div class="row">
                        <div
                            :class="[4].includes(connectivityData?.id) && connectivityData?.details?.chartData?.length>0 ? 'col-md-6' : ''">
                            <div class="card-content border-0">
                                <div class="card-box">
                                    <div class="card-left-icon">
                                        <IconQuestion />
                                    </div>
                                    <div class="card-detail">
                                        <h3>{{connectivityData?.services?.[0]?.title}}</h3>
                                        <p> {{connectivityData?.services?.[0]?.details}}
                                        </p>

                                        <div v-if="[4].includes(connectivityData?.id)"
                                            class="max-h-480 overflow-y-auto">
                                            <ul>
                                                <li v-for="(data, index) in connectivityData?.details?.towers"
                                                    :key="index">
                                                    <a :class="selectedWispTowerIndex == index ? 'active' : '' "
                                                        @click="switchWispTowerHandler(index)" href="#">
                                                        <span class="icon">
                                                            <IconTower />
                                                        </span>
                                                        <span class="text">
                                                            {{data}}
                                                        </span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div v-if="[4].includes(connectivityData?.id) && connectivityData?.details?.chartData?.length>0"
                            class="col-md-6">
                            <div class="py-4">

                                <div class="chart-box">
                                    <Chart>
                                        <ChartCategoryAxis>
                                            <ChartCategoryAxisItem />
                                        </ChartCategoryAxis>
                                        <ChartSeries>
                                            <ChartSeriesItem :markers="false"
                                                :data-items="seriesFunc(connectivityData?.details?.chartData[selectedWispTowerIndex])"
                                                :type="'line'" :style="{ strokeWidth: 3 }" />
                                            <ChartSeriesItem :type="'area'"
                                                :data-items="connectivityData?.details?.chartData[selectedWispTowerIndex]" />
                                        </ChartSeries>
                                    </Chart>
                                    <!-- <div class="text-center">
                                        <p
                                            class="max-w-408 font-s-13 font-w-normal line-h-1_4 ms-auto me-auto mb-0">
                                            {{connectivityData?.details[selectedTowerIndex]?.chartTitle}}</p>
                                    </div> -->
                                    <!-- <div class="text-center">
                                        <k-button
                                            @click="chartSeeMoreHandler(connectivityData?.details[selectedTowerIndex])"
                                            :fill-mode="'flat'" :class="'btn-text-blue'">See More</k-button>
                                    </div> -->
                                </div>
                            </div>
                        </div>
                    </div>




                </div>
                <div v-if="connectivityData?.id == 7" class="app-card"
                    :class="connectivityData?.is_selected ? 'selected' : ''">
                    <div class="card-header">
                        <p>{{connectivityData?.title}}</p>
                        <k-button @click="selectedCardHandler(index)" :class="'btn-select'" :theme-color="'primary'"
                            :fill-mode="connectivityData?.is_selected ? 'solid' : 'outline'"
                            :icon="connectivityData?.is_selected ? 'check' : 'plus'">{{connectivityData?.is_selected ?
                            'Selected' : 'Select'}}</k-button>
                    </div>
                    <div class="card-content">
                        <div class="card-box">
                            <div class="card-left-icon">
                                <IconQuestion />
                            </div>
                            <div class="card-detail">
                                <div class="row">
                                    <div class="col-md-6">
                                        <!-- <h3>{{connectivityData?.sub_title}}</h3> -->
                                        <h3 v-if="connectivityData?.messages && connectivityData?.messages.length > 0">
                                            <strong>{{connectivityData?.messages}}</strong>
                                        </h3>
                                        <h3 v-else>{{connectivityData?.sub_title}}</h3>
                                        <div v-if="connectivityData?.details?.length" class="max-h-480 overflow-y-auto">
                                            <ul>
                                                <li v-for="(data, index) in connectivityData?.details" :key="index">
                                                    <div @click="switchBroadbandHandler(index)" class="list-div">
                                                        <span class="icon">
                                                            <IconTower />
                                                        </span>
                                                        <span class="text">
                                                            {{data?.outcome}}
                                                        </span>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div v-if="markerLocationsData?.length > 0" class="chart-box">
                                            <googleMapPreview>
                                            </googleMapPreview>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card-content"
                        v-for="(connectivityDataServices, indexServices) in connectivityData?.services">
                        <div class="card-box">
                            <div class="card-left-icon">
                                <IconQuestion />
                            </div>
                            <div class="card-detail flex-grow-1 pe-3">
                                <h3>{{connectivityDataServices?.title}}</h3>
                                <p> {{connectivityDataServices?.details}}</p>
                            </div>
                            <k-button @click="selectedCardHandlerDataService(connectivityDataServices)"
                                :class="'btn-select'" :theme-color="'primary'"
                                :fill-mode="connectivityDataServices?.is_selected ? 'solid' : 'outline'"
                                :icon="connectivityDataServices?.is_selected ? 'check' : 'plus'">{{connectivityDataServices?.is_selected
                                ?
                                'Selected' : 'Select'}}</k-button>
                        </div>
                    </div>

                </div>
                <div v-if="[8].includes(connectivityData?.id)" class="app-card">
                    <div class="card-content">
                        <div class="add-card">
                            <div class="d-flex">
                                <div class="flex-grow-1 pe-3">
                                    <h3>{{connectivityData?.title}}</h3>
                                    <div class="card-form">
                                        <p>
                                            {{connectivityData?.sub_title}}
                                        </p>
                                    </div>
                                </div>
                                <k-button @click="selectedConnectivityData(index)" :class="'btn-select'"
                                    :theme-color="'primary'"
                                    :fill-mode="connectivityData?.is_selected ? 'solid' : 'outline'"
                                    :icon="connectivityData?.is_selected ? 'check' : 'plus'">{{connectivityData?.is_selected
                                    ?
                                    'Selected' : 'Select'}}</k-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="isEditModeEnabled" v-for="(connectivityData, index) in connectivityLookupData?.details">
                <div>
                    <div>
                        <div v-if="[1,2,3,4,5,6].includes(connectivityData?.id)">
                            <div class="app-card">
                                <div class="card-header">
                                    <p>{{connectivityData?.title}}</p>
                                </div>
                                <div class="card-content">
                                    <div class="card-box">
                                        <div class="card-left-icon">
                                            <IconQuestion />
                                        </div>
                                        <div class="card-detail">
                                            <h3>{{connectivityData?.services?.[0]?.title}}</h3>
                                            <k-textarea :rows="4" :value="connectivityData?.services?.[0]?.details"
                                                @change="(e) => serviceDataHandleChange(e, index)"
                                                :class="'min-h-148'" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="[7].includes(connectivityData?.id)">
                            <div class="app-card">
                                <div class="card-header">
                                    <p>{{connectivityData?.title}}</p>
                                </div>
                                <div class="card-content">
                                    <div class="card-box"
                                        v-for="(connectivityDataServices, indexServices) in connectivityData?.services">
                                        <div class="card-left-icon">
                                            <IconQuestion />
                                        </div>
                                        <div class="card-detail">
                                            <h3>{{connectivityDataServices.title}}</h3>
                                            <k-textarea :rows="4" :value="connectivityDataServices?.details"
                                                @change="(e) => connectivityDataHandleChange(e, index, indexServices)"
                                                :class="'min-h-148'" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="[8].includes(connectivityData?.id)" class="app-card mb-0 border-bottom-0">
                        <div class="card-content">
                            <div class="add-card">
                                <h3>{{connectivityData?.title}}</h3>
                                <div class="card-form">
                                    <k-textarea :rows="4" :value="connectivityData?.sub_title"
                                        @change="(e) => serviceDataHandleChange(e, index)" :class="'min-h-148'" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-footer bg-white py-26 px-16">
                <div class="d-flex justify-content-between">
                    <div>
                        <k-button v-if="!isEditModeEnabled" @click="gotoRecent" :theme-color="'dark'">Cancel</k-button>
                        <k-button v-if="isEditModeEnabled" @click="onCancelEditMode"
                            :theme-color="'dark'">Cancel</k-button>
                    </div>
                    <div>
                        <k-button v-if="!isEditModeEnabled" @click="editOptionHandler" :class="'ms-auto me-3'"
                            :theme-color="'dark'">Edit & Submit</k-button>
                        <k-button v-if="!isEditModeEnabled" @click="showUserModal"
                            :theme-color="'primary'">Submit</k-button>
                        <k-button v-if="isEditModeEnabled" @click="showUserModal" :theme-color="'primary'">Save &
                            Submit</k-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <userDetailsModal v-if="showUserModals" :submit="onSubmitUserModal" :show="showUserModals"
        :close="closeUserDetailsModal" />

    <towerInfoModal v-if="towerInfoModalVisibility" :show="towerInfoModalVisibility" :towerInfo="selectedTowerInfo"
        :close="closeTowerInfoModal">
    </towerInfoModal>
</div>