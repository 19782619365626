<template>
  <div class="app-search-loader display-center">
    <div class="text-center">
      <img src="~@/assets/images/loader.gif" style="width: 100px;" alt="loader">
      <div class="mt-3">
        <p class="font-s-16 text-gray-primary font-w-normal">Checking Loxcel...</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppSearchLoader"
}
</script>

<style scoped>

</style>