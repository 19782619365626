<template>
  <svg width="18" height="22" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.4007 8.60835C11.4007 9.93384 10.3261 11.0084 9.00066 11.0084C7.67517 11.0084 6.60066 9.93384 6.60066 8.60835C6.60066 7.28287 7.67517 6.20836 9.00066 6.20836C10.3261 6.20836 11.4007 7.28287 11.4007 8.60835Z" stroke="#4540DB" stroke-width="2" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "IconMap"
}
</script>

<style scoped>

</style>