<template>
  <div class="search-bar">
    <div class="search-bar-box">
      <!-- Search filter-->
      <div class="search-items">
        <div class="search-filter search-dropdown-filter">
          <span class="title">Search Type</span>
          <div class="k-form-field-wrap">
            <DropDownList
              :class="'filter-dropdown'"
              :fill-mode="'flat'"
              :icon="'arrow-chevron-down'"
              :text-field="'text'"
              :data-items="dropdownList"
              :default-item="defaultItem"
              :value="searchTypeHandlerValue"
              @change="searchTypeHandler"
            />
          </div>
        </div>
        <div
          v-if="![3].includes(searchTypeHandlerValue?.id)"
          class="search-filter"
          :class="!searchTypeHandlerValue?.id ? 'disabled' : ''"
        >
          <span class="title">GPS Coordinates</span>
          <div class="k-form-field-wrap">
            <k-input
              :placeholder="'Please Type'"
              @input="gpsCoordinates"
              v-model="gpsCoordinatesValue"
              @focusout="gpsCoordinatesHandler"
            />
            <span class="text-danger font-s-11 mt-3">{{
              errorArray["GPSCoordinates"]
            }}</span>
          </div>
        </div>
        <div
          class="search-filter"
          ref="searchArea"
          id="searchBox"
          :class="!searchTypeHandlerValue?.id ? 'disabled' : ''"
        >
          <span class="title">Address</span>
          <div class="k-form-field-wrap map-filter">
            <div class="map-icon">
              <img src="~@/assets/images/map.png" alt="blue" />
            </div>

            <k-input
              @input="debounceSearch"
              :placeholder="'Please Type'"
              v-model="locationAddress.formattedAddress"
            />
            <span class="text-danger font-s-11 mt-3">{{
              errorArray["address"]
            }}</span>
            <Popup
              :anchor="'searchArea'"
              :show="showSearch"
              :popup-class="'search-popup'"
              :class="'search-popup-wrap'"
            >
              <div
                id="searchBoxInner"
                class="search-inner mapbox-inside"
                :class="
                  searchTypeHandlerValue?.id === 3 ? 'search-inner-full' : ''
                "
              >
                <!-- <div id="searchBoxInner" class="search-inner"
                :class="searchTypeHandlerValue?.id === 3 ? 'search-inner-full' : ''"> -->
                <div class="mapbox">
                  <googleMapDragMarker v-if="selectedAddressForSearch?.lat" />
                </div>

                <div v-if="isAddressLoading" class="search-loader">
                  <AppGlobalLoader v-if="isAddressLoading" />
                </div>
                <div v-else class="content d-flex flex-column">
                  <div class="search-lists">
                    <div :class="selectedAddressForSearch?.id == location?.id? 'active' : ''" v-for="(location, index) in locationsId" :key="index" @click="selectAddress(location)" class="search-item">
                      <div v-if="location?.id" class="icon"><img src="~@/assets/images/rth.png" alt="RTH" /></div>
                      <div v-else class="icon"><img src="~@/assets/images/map.png" alt="blue" /></div>
                      <div class="text"><span>{{location?.formattedAddress}}</span></div>
                    </div>
                  </div>

                  <div @click="showAddressPopUpHandler" class="search-no-data d-flex align-items-center cursor-pointer">
                    <k-button :class="'btn-no-hover'" :fill-mode="'flat'">
                      <img src="~@/assets/images/map-blue.png" alt="blue" />
                    </k-button>
                    <p class="m-0">I could not locate my address</p>
                  </div>

                  <div class="d-flex justify-content-end p-2 mt-auto">
                    <k-button @click="saveSearchDataHandler" :theme-color="'primary'" :class="'me-1'">Save</k-button>
                    <k-button @click="cancelSearchDataHandler" :theme-color="'primary'" :class="'ms-1'">Cancel</k-button>
                  </div>

                </div>
              </div>
            </Popup>
          </div>
        </div>
        <div
          v-if="![3].includes(searchTypeHandlerValue?.id)"
          class="search-filter"
          :class="!searchTypeHandlerValue?.id ? 'disabled' : ''"
        >
          <span class="title">Antenna Height</span>
          <div class="k-form-field-wrap">
            <NumericTextBox
              @change="antennaHeightHandler"
              :value="antennaHeight"
              :class="'h-38'"
              type="number"
              :placeholder="'Please Type'"
            />
            <span class="text-danger font-s-11 mt-3">{{
              errorArray["antennaHeight"]
            }}</span>
          </div>
        </div>
      </div>
      <!-- Search Action-->
      <div class="search-action">
        <k-button
          :disabled="!searchTypeHandlerValue?.id"
          @click="goForSearch"
          :theme-color="'primary'"
          >GO</k-button
        >
      </div>
      <AppModal
        :modal-title="'Enter Address Manually'"
        :close="showAddressPopUpHandler"
        :modal-show="showAddressPopUp"
      >
        //showAddressPopUp
        <template v-slot:content="">
          <div class="add-address-modal">
            <div class="head mb-3">
              <p class="font-s-16 text-actions-dark">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.
              </p>
            </div>
            <div class="content">
              <k-form>
                <form-element>
                  <div class="row">
                    <div class="col-md-6">
                      <field-wrapper>
                        <k-label>Unit Type</k-label>
                        <div v-if="addressUnits" class="k-form-field-wrap">
                          <DropDownList
                            :class="'dropdown-as-input'"
                            :fill-mode="'outline'"
                            :icon="'arrow-chevron-down'"
                            :text-field="'name'"
                            :data-items="addressUnits"
                            :default-item="{
                              id: 0,
                              name: 'Select Unit Type   ...',
                            }"
                            :data-item-key="'id'"
                            @change="onchangeUnitType"
                          />
                        </div>
                      </field-wrapper>
                    </div>
                    <div class="col-md-6">
                      <field-wrapper>
                        <k-label>Sub Address Number</k-label>
                        <div class="k-form-field-wrap">
                          <k-input
                            :placeholder="'56899'"
                            v-model="addressSearchStructured.address_number"
                          />
                        </div>
                      </field-wrapper>
                    </div>
                    <div class="col-md-6">
                      <field-wrapper>
                        <k-label>Street Number</k-label>
                        <div class="k-form-field-wrap">
                          <k-input
                            @keyup="this.errorMessage['street_number'] = ''"
                            :placeholder="'19'"
                            v-model="addressSearchStructured.street_number"
                          />
                          <k-error class="form-alert-position">{{
                            this.errorMessage["street_number"]
                          }}</k-error>
                        </div>
                      </field-wrapper>
                    </div>
                    <div class="col-md-6">
                      <field-wrapper>
                        <k-label>Street Name</k-label>
                        <div class="k-form-field-wrap">
                          <k-input
                            @keyup="this.errorMessage['street_name'] = ''"
                            :placeholder="'Annie Street'"
                            v-model="addressSearchStructured.street_name"
                          />
                          <k-error class="form-alert-position">{{
                            this.errorMessage["street_name"]
                          }}</k-error>
                        </div>
                      </field-wrapper>
                    </div>
                    <div class="col-md-6">
                      <field-wrapper>
                        <k-label>Street Types</k-label>
                        <div class="k-form-field-wrap">
                          <DropDownList
                            :class="'dropdown-as-input'"
                            :fill-mode="'outline'"
                            :icon="'arrow-chevron-down'"
                            :data-item-key="'id'"
                            :text-field="'name'"
                            :data-items="addressStreets"
                            :default-item="{
                              id: 0,
                              name: 'Select Street Types   ...',
                            }"
                            @change="onchangeStreetTypes"
                          />
                          <k-error class="form-alert-position">{{
                            this.errorMessage["street_type"]
                          }}</k-error>
                        </div>
                      </field-wrapper>
                    </div>
                    <div class="col-md-6">
                      <field-wrapper>
                        <k-label>Suburb / Town</k-label>
                        <div class="k-form-field-wrap">
                          <k-input
                            @keyup="this.errorMessage['town'] = ''"
                            :placeholder="'Annie Street'"
                            v-model="addressSearchStructured.town"
                          />
                          <k-error class="form-alert-position">{{
                            this.errorMessage["town"]
                          }}</k-error>
                        </div>
                      </field-wrapper>
                    </div>
                    <div class="col-md-6">
                      <field-wrapper>
                        <k-label>State</k-label>
                        <div class="k-form-field-wrap">
                          <DropDownList
                            :class="'dropdown-as-input'"
                            :fill-mode="'outline'"
                            :icon="'arrow-chevron-down'"
                            :data-item-key="'id'"
                            :text-field="'name'"
                            :data-items="addressStates"
                            :default-item="{
                              id: 0,
                              name: 'Select Street Types   ...',
                            }"
                            @change="onchangeState"
                          />
                          <k-error class="form-alert-position">{{
                            this.errorMessage["state"]
                          }}</k-error>
                        </div>
                      </field-wrapper>
                    </div>
                    <div class="col-md-6">
                      <field-wrapper>
                        <k-label>Postcode</k-label>
                        <div class="k-form-field-wrap">
                          <k-input
                            :placeholder="'4671'"
                            v-model="addressSearchStructured.postcode"
                          />
                        </div>
                      </field-wrapper>
                    </div>
                  </div>
                </form-element>
              </k-form>
            </div>
          </div>
        </template>
        <template v-slot:footer="">
          <k-button :theme-color="'dark'" @click="showAddressPopUpHandler"
            >Cancel</k-button
          >
          <k-button :theme-color="'primary'" @click="onModalSave"
            >Save</k-button
          >
        </template>
      </AppModal>
    </div>
  </div>
</template>

<script>
import { Button } from "@progress/kendo-vue-buttons";
import { Label } from "@progress/kendo-vue-labels";
import { Input } from "@progress/kendo-vue-inputs";
import { DropDownList } from "@progress/kendo-vue-dropdowns";
import { Popup } from "@progress/kendo-vue-popup";
import IconMap from "@/components/svg-icons/IconMap.vue";
import AppModal from "@/components/commons/app-modal/AppModal.vue";
import { FieldWrapper, Form, FormElement } from "@progress/kendo-vue-form";
import { Error } from "@progress/kendo-vue-labels/dist/esm/main";
import { NumericTextBox } from "@progress/kendo-vue-inputs";
import AppGlobalLoader from "@/components/commons/app-global-loader/AppGlobalLoader.vue";
import { getAddressCoordinates } from "@/services/LocSeach.service";
import googleMapDragMarker from "../google-map-for-address-search/googleMapDragMarker.vue";
export default {
  name: "AppSearchBar",
  components: {
    "k-form": Form,
    "field-wrapper": FieldWrapper,
    "form-element": FormElement,
    "k-error": Error,
    "k-button": Button,
    "k-input": Input,
    "k-label": Label,
    DropDownList,
    Popup,
    IconMap,
    AppModal,
    NumericTextBox,
    AppGlobalLoader,
    googleMapDragMarker,
  },

  data: function () {
    return {
      showSearch: false,
      showAddressPopUp: false,
      dropdownList: [
        { id: 1, text: "New Connectivity Report" },
        { id: 2, text: "New Technical Lookup" },
        { id: 3, text: "Lookup LOCID" },
        // { id: 4, text: "New Site Lookup" },
        // { id: 5, text: "Lookup FW Range" },
      ],
      defaultItem: {
        text: "Please Select",
      },
      lookFWRangeModel: {
        latitude: "",
        longitude: "",
        height: "",
      },
      errorMessage: [],
      addressSearchStructured: {
        unit_type: "",
        street_number: "",
        street_name: "",
        street_type: "",
        state: "",
        town: "",
        postcode: "",
        address_number: "",
      },
    };
  },
  computed: {
    errorArray() {
      return this.$store.state.locSearch.errorArrayForGlobalSearch;
    },
    gpsCoordinatesValue() {
      return this.$store.state.locSearch.globalSearchData?.coordinates;
    },
    antennaHeight() {
      return this.$store.state.locSearch.globalSearchData?.antennaHeight;
    },
    locationAddress() {
      return this.$store.state.locSearch.globalSearchData?.address;
    },
    searchTypeHandlerValue() {
      return this.$store.state.locSearch.globalSearchData?.searchType;
    },
    selectedAddressForSearch() {
      return this.$store.state.locSearch.selectedAddressForSearch;
    },
    globalSearchData() {
      return this.$store.state.locSearch.globalSearchData;
    },
    locationsId() {
      return this.$store.state.locSearch.locations;
    },
    addressUnits() {
      return this.$store.state.locSearch.addressUnitList;
    },
    addressStates() {
      return this.$store.state.locSearch.addressStatesList;
    },
    addressStreets() {
      return this.$store.state.locSearch.addressStreetsList;
    },
    isGlobalLoading() {
      return this.$store.state.locSearch.isGlobalLoading;
    },
    isAddressLoading() {
      return this.$store.state.locSearch.isAddressLoading;
    },
    getTechnicalLookupOutput() {
      return this.$store.state.locSearch.getTechnicalLookupOutput;
    },
  },
  mounted() {
    // this.$store.commit("SET_ISEMPTY_STATE", false);
    // this.$store.commit("SET_NEWCONNECTIVITYREPORT_COMPONENT_SHOWHIDE", true);
    document.addEventListener("click", this.documentClick);
  },
  props: {
    isCLean: Boolean,
  },

  watch: {
    isCLean(newValue, oldValue) {
      this.clearSearchBarValue();
      this.$emit("refreshCleanData");
    },
  },

  methods: {
    gpsCoordinatesHandler() {
      this.globalSearchErrorPush({
        key: "address",
        value: "",
      });
      this.globalSearchErrorPush({
        key: "GPSCoordinates",
        value: "",
      });

      if ([1, 2].includes(this.searchTypeHandlerValue?.id)) {
        if (this.gpsCoordinatesValue) {
          this.$store.commit("SET_GLOBAL_SEARCH_DATA", {
            coordinates: this.gpsCoordinatesValue,
          });

          this.$store.commit("SET_LOCATION_DATA", {
            lat: +this.gpsCoordinatesValue.split(",")[0],
            lng: +this.gpsCoordinatesValue.split(",")[1],
          });
        }
      }
    },
    globalSearchErrorPush(data) {
      this.$store.commit("SET_ERROR_ARRAY_FOR_GLOBAL_SEARCH", data);
    },
    cancelSearchDataHandler() {
      this.showSearch = false;
      // this.$store.commit("SET_GLOBAL_SEARCH_DATA", {
      //   address: {
      //     id: "",
      //     formattedAddress: ""
      //   },
      // });
      this.$store.commit("SET_SELECTED_ADDRESS_FOR_SEARCH", null);
      this.$store.commit("SET_ADDRESS_MAP_LOCATION_DATA", null);
    },
    saveSearchDataHandler() {
      this.showSearch = false;
      this.globalSearchErrorPush({
        key: "address",
        value: "",
      });
      this.globalSearchErrorPush({
        key: "GPSCoordinates",
        value: "",
      });
      this.$store.commit("SET_GLOBAL_SEARCH_DATA", {
        address: this.selectedAddressForSearch,
      });
      if ([1, 2].includes(this.searchTypeHandlerValue?.id)) {
        this.$store.commit("SET_LOCATION_DATA", {
          lat: +this.selectedAddressForSearch.lat,
          lng: +this.selectedAddressForSearch.lng,
        });
        this.$store.commit("SET_GLOBAL_SEARCH_DATA", {
          coordinates:
            this.selectedAddressForSearch?.lat +
            "," +
            this.selectedAddressForSearch?.lng,
        });
      }
      this.$store.commit("SET_LOOKUPLOC_ID_LOCATIONS", []);
    },
    showSearchHandler(e) {
      this.showSearch = !this.showSearch;
    },
    clearSearchBarValue() {
      // this.locationAddress = {
      //   id: "",
      //   formattedAddress: ""
      // },
      this.$store.commit("SET_GLOBAL_SEARCH_DATA", {
        searchType: { id: 0, text: "Please Select" },
        coordinates: "",
      });
    },
    debounceSearch(event) {
      if (this.locationAddress?.id) {
        this.$store.commit("SET_GLOBAL_SEARCH_DATA", {
          address: {
            id: "",
            formattedAddress: "",
          },
        });
      }
      if (event.value.length > 0) {
        this.$store.commit("SET_ISSEARCHLOADING_LOCSEARCH", true);
        clearTimeout(this.debounce);
        this.debounce = setTimeout(() => {
          this.$store.commit("SET_ISSEARCHLOADING_LOCSEARCH", true);
          this.onSearch(event);
          this.showSearch = true;
        }, 600);
      }
    },
    documentClick(e) {
      const el = e.target;
      const el_popup = document.getElementById("searchBox");
      const el_popup_inner = document.getElementById("searchBoxInner");
      if (el_popup !== null && el_popup_inner !== null) {
        if (el_popup.contains(el)) {
        } else if (el_popup_inner.contains(el)) {
        } else {
          // this.showSearch = false;
        }
      }
    },
    onSearch(event) {
      let payload = {
        value: event.value,
        searchTypeHandlerValue: this.searchTypeHandlerValue?.id,
      };
      this.$store.dispatch("getLookuplocIdAddressSearch", payload);
    },
    showAddressPopUpHandler() {
      this.showSearch = false;
      this.showAddressPopUp = !this.showAddressPopUp;
      this.$store.dispatch("getAddressUnitList");
      this.$store.dispatch("getAddressStatesList");
      this.$store.dispatch("getAddressStreetsList");
    },
    searchTypeHandler(e) {
      this.$store.commit("SET_GLOBAL_SEARCH_DATA", {
        address: {
          id: "",
          formattedAddress: "",
        },
        coordinates: "",
        antennaHeight: "",
      });
      this.globalSearchErrorPush([]);
      this.$store.commit("SET_LookupLOCID_COMPONENT_SHOWHIDE", false);
      this.$store.commit("SET_LOOKUPFWRANGE_COMPONENT_SHOWHIDE", false);
      this.$store.commit("SET_ISEMPTY_STATE", false);
      this.$store.commit("SET_NEWCONNECTIVITYREPORT_COMPONENT_SHOWHIDE", false);
      this.$store.commit("SET_NEWTECHNICALLOOKUP_COMPONENT_SHOWHIDE", false);
      this.$store.commit("SET_NEWSITELOOKUP_COMPONENT_SHOWHIDE", false);
      this.$store.commit("SET_RECENTSEARCH_COMPONENT_SHOWHIDE", true);
      this.$store.commit("SET_MENU_MANAGEMENT_COMPONENT_SHOWHIDE", false);
      this.$store.commit("SET_REGISTRATION_FORM_COMPONENT_SHOWHIDE", false);
      this.$store.commit("SET_GLOBAL_SEARCH_DATA", {
        searchType: e.value,
      });

      // if (e.value.id == 1) {
      //   this.$store.dispatch("getConnectivityReportsData", {});
      //   this.$store.commit("SET_ISEMPTY_STATE", false);
      // }
    },
    selectAddress(address) {
      // console.log('address -- c92', address);

      this.$store.commit("SET_ADDRESS_MAP_LOCATION_DATA", {
        lat: +address.lat,
        lng: +address.lng,
      });

      this.$store.commit("SET_SELECTED_ADDRESS_FOR_SEARCH", address);
      return;
    },
    gpsCoordinates(e) {
      this.globalSearchErrorPush({
        key: "GPSCoordinates",
        value: "",
      });
      this.$store.commit("SET_GLOBAL_SEARCH_DATA", {
        coordinates: e.value,
      });
    },
    antennaHeightHandler(e) {
      this.globalSearchErrorPush({
        key: "antennaHeight",
        value: "",
      });
      this.lookFWRangeModel.height = e.value;
      this.$store.commit("SET_GLOBAL_SEARCH_DATA", {
        antennaHeight: e.value,
      });
      if (
        this.antennaHeight < 2 ||
        this.antennaHeight > 250 ||
        this.antennaHeight == ""
      ) {
        this.globalSearchErrorPush({
          key: "antennaHeight",
          value: "Antenna Height is between 2 and 250.",
        });
        return;
      }
    },
    onchangeUnitType(e) {
      this.addressSearchStructured.unit_type = e?.value?.name;
    },
    onchangeStreetTypes(e) {
      this.errorMessage["street_type"] = "";
      this.addressSearchStructured.street_type = e?.value?.name;
    },
    onchangeState(e) {
      this.errorMessage["state"] = "";
      this.addressSearchStructured.state = e?.value?.name;
    },
    isVerify() {
      let isError = false;
      if (this.addressSearchStructured.street_number == "") {
        this.errorMessage["street_number"] =
          "The street number field is required.";
        isError = true;
      }
      if (this.addressSearchStructured.street_name == "") {
        this.errorMessage["street_name"] = "The street name field is required.";
        isError = true;
      }
      if (this.addressSearchStructured.street_type == "") {
        this.errorMessage["street_type"] = "The street type field is required.";
        isError = true;
      }
      if (!this.addressSearchStructured.state) {
        this.errorMessage["state"] = "The state field is required.";
        isError = true;
      }
      if (this.addressSearchStructured.town == "") {
        this.errorMessage["town"] = "Town required custom message";
        isError = true;
      }
      return isError;
    },

    getAddressCoordinate(payload) {
      getAddressCoordinates(payload)
        .then((response) => {
          const locationData = response?.data?.data;
          this.$store.commit("SET_LOCATION_DATA", locationData);
          const coordinates = locationData?.lat + "," + locationData?.lng;
          this.$store.commit("SET_GLOBAL_SEARCH_DATA", {
            coordinates: coordinates,
          });
        })
        .catch(() => {
          commit("SET_ISGLOBALLOADING_LOCSEARCH", false);
        });
    },
    isCLean() {},
    onModalSave() {
      if (this.isVerify()) {
        return;
      }
      this.$store.commit("SET_ISGLOBALLOADING_LOCSEARCH", true);
      this.$store.dispatch(
        "getAddressSearchStructured",
        this.addressSearchStructured
      );
      this.showAddressPopUp = false;
      this.showSearch = true;
      this.addressSearchStructured = {
        unit_type: "",
        street_number: "",
        street_name: "",
        street_type: "",
        state: "",
        town: "",
        postcode: "",
        address_number: "",
      }; //will add model
    },
    dataValidation() {
      var isValid = true;
      this.errorArray["GPSCoordinates"] = "";
      this.errorArray["address"] = "";
      this.errorArray["antennaHeight"] = "";
      const numberRegex = /^[+-]?\d+(?:\.\d+)?,\s*[+-]?\d+(?:\.\d+)?$/;

      if ([1, 2]?.includes(this.searchTypeHandlerValue?.id)) {
        if (!numberRegex.test(this.gpsCoordinatesValue)) {
          this.globalSearchErrorPush({
            key: "GPSCoordinates",
            value: "Please provide latitude and longitude separated by comma.",
          });
          isValid = false;
        }
        /*if (!this.locationAddress?.id) {
          this.errorArray["address"] =
            "Please Select an address with location id.";
          isValid = false;
        }*/
        if (!this.antennaHeight) {
          this.errorArray["antennaHeight"] = "Please provide antenna height. ";
          isValid = false;
        }
        if (this.gpsCoordinatesValue.split(",").length == 2) {
          this.lookFWRangeModel.latitude =
            this.gpsCoordinatesValue.split(",")[0];
          this.lookFWRangeModel.longitude =
            this.gpsCoordinatesValue.split(",")[1];
          if (
            this.lookFWRangeModel.latitude < -80 ||
            this.lookFWRangeModel.latitude > 80
          ) {
            this.errorArray["GPSCoordinates"] =
              "Latitude is between -80 and 80.";
            isValid = false;
          }
          if (
            this.lookFWRangeModel.longitude < -180 ||
            this.lookFWRangeModel.latitude > 180
          ) {
            this.errorArray["GPSCoordinates"] =
              "Longitude is between -180 and 180.";
            isValid = false;
          }
        }
      }
      return isValid;
    },
    goForSearch() {
      this.$store.commit("SET_RECENTSEARCH_COMPONENT_SHOWHIDE", false);
      if (this.searchTypeHandlerValue?.id == 1) {
        if (this.dataValidation()) {
          this.$store.commit("SET_ISEMPTY_STATE", false);
          this.$store.commit(
            "SET_NEWCONNECTIVITYREPORT_COMPONENT_SHOWHIDE",
            true
          );
          this.$store.dispatch("getConnectivityLookupOutput", {
            location_id: this.locationAddress?.id,
            latitude: this.lookFWRangeModel.latitude,
            longitude: this.lookFWRangeModel.longitude,
            location_address: this.locationAddress?.formattedAddress,
            antenna_height: this.antennaHeight,
          });
        } else {
          return;
        }
      }
      if (this.searchTypeHandlerValue?.id == 2) {
        if (this.dataValidation()) {
          this.$store.dispatch("getTechnicalLookupOutput", {
            location_id: this.locationAddress?.id,
            latitude: this.lookFWRangeModel.latitude,
            longitude: this.lookFWRangeModel.longitude,
            location_address: this.locationAddress?.formattedAddress,
            antenna_height: this.antennaHeight,
          });
          // this.$store.commit("SET_NEWTECHNICALLOOKUP_COMPONENT_SHOWHIDE", true);
        } else {
          return;
        }
      }
      if (this.searchTypeHandlerValue?.id == 3) {
        if (!this.locationAddress?.id) {
          this.$store.commit("SET_NOTIFICATION_DATA", {
            type: "error",
            message: "LOCID unavailable.",
            visibility: true,
          });
          return;
        } else {
          this.$store.commit("SET_ISLOADING_LOCSEARCH", true);
          this.$store.dispatch(
            "getLookupLOCIDOutput",
            this.locationAddress?.id
          );
        }
      }
      if (this.searchTypeHandlerValue?.id == 4) {
        var isValid = true;
        this.errorArray["GPSCoordinates"] = "";
        const numberRegex = /^[+-]?\d+(?:\.\d+)?,\s*[+-]?\d+(?:\.\d+)?$/;
        if (!numberRegex.test(this.gpsCoordinatesValue)) {
          this.errorArray["GPSCoordinates"] =
            "Please provide latitude and longitude separated by comma.";
          isValid = false;
        }
        if (!this.locationAddress?.formattedAddress) {
          // this.errorArray['add'] = "Please provide latitude and longitude separated by comma.";
          isValid = false;
        }
        if (isValid) {
          if (this.gpsCoordinatesValue.split(",").length == 2) {
            this.lookFWRangeModel.latitude =
              this.gpsCoordinatesValue.split(",")[0];
            this.lookFWRangeModel.longitude =
              this.gpsCoordinatesValue.split(",")[1];
            if (
              this.lookFWRangeModel.latitude < -80 ||
              this.lookFWRangeModel.latitude > 80
            ) {
              this.errorArray["GPSCoordinates"] =
                "Latitude is between -80 and 80.";
              return;
            }
            if (
              this.lookFWRangeModel.longitude < -180 ||
              this.lookFWRangeModel.latitude > 180
            ) {
              this.errorArray["GPSCoordinates"] =
                "Longitude is between -180 and 180.";
              return;
            }
          }

          this.$store.dispatch("getNewSiteLookupOutput");
        } else {
          return;
        }
      }
      if (this.searchTypeHandlerValue?.id == 5) {
        this.errorArray["antennaHeight"] = "";
        this.errorArray["GPSCoordinates"] = "";
        var isValid = true;
        const numberRegex = /^[+-]?\d+(?:\.\d+)?,\s*[+-]?\d+(?:\.\d+)?$/;

        if (
          this.lookFWRangeModel.height < 2 ||
          this.lookFWRangeModel.height > 250 ||
          this.lookFWRangeModel.height == ""
        ) {
          this.errorArray["antennaHeight"] =
            "Antenna Height is required and between 2 and 250.";
          isValid = false;
        }
        if (!numberRegex.test(this.gpsCoordinatesValue)) {
          this.errorArray["GPSCoordinates"] =
            "Please provide latitude and longitude separated by comma.";
          isValid = false;
        }
        if (isValid) {
          if (this.gpsCoordinatesValue.split(",").length == 2) {
            this.lookFWRangeModel.latitude =
              this.gpsCoordinatesValue.split(",")[0];
            this.lookFWRangeModel.longitude =
              this.gpsCoordinatesValue.split(",")[1];
            if (
              this.lookFWRangeModel.latitude < -80 ||
              this.lookFWRangeModel.latitude > 80
            ) {
              this.errorArray["GPSCoordinates"] =
                "Latitude is between -80 and 80.";
              return;
            }
            if (
              this.lookFWRangeModel.longitude < -180 ||
              this.lookFWRangeModel.latitude > 180
            ) {
              this.errorArray["GPSCoordinates"] =
                "Longitude is between -180 and 180.";
              return;
            }
          }
          this.$store.commit("SET_ISLOADING_LOCSEARCH", true);
          this.$store.dispatch("getLookFWRangeDetails", this.lookFWRangeModel);
        } else {
          return;
        }
      }
    },
  },
};
</script>

<style scoped></style>
