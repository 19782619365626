<template src="./RecentSearches.html"></template>

<script>
import { Grid } from '@progress/kendo-vue-grid';
import { Button } from "@progress/kendo-vue-buttons";
import { dateFormatter } from "@/helpers/utility";
export default {
  name: "RecentSearches",
  components: {
    'grid': Grid,
    'k-button': Button,
  },
  data: function () {
    return {
      take: 10,
      gridPageable: {
        info: true,
        previousNext: true,
      },
      columns: [
        { field: 'customer_name', title: 'Name'},
        { field: 'lat', title: 'Latitude'},
        { field: 'lng', title: 'Longitude'},
        { field: 'output', title: 'Output string'},
        { field: 'Actions', title: 'Action', cell: 'actionsTemplate'},
      ],
    };
  },
  methods: {
    pageChangeHandler(e) {
      this.$store.dispatch("getConnectivityReportsData", {
        page: (e?.page?.skip + e?.page?.take) / this.take
      });
    },
    formatDate(date, format) {
      return dateFormatter(date, format);
    },
    regenerateHandler(id) {
      this.$store.commit("SET_ERROR_ARRAY_FOR_GLOBAL_SEARCH", []);
      this.$store.dispatch("getRegenerateConnectivityReport", {
        report_id: id
      });
    }
  },
  computed: {
    total() {
      return this.$store.state.locSearch.connectivityReportData?.total || 0
    },
    getConnectivityReportsData() {
      return this.$store.state.locSearch.connectivityReportData?.data;
    },
    skip() {
      return this.$store.state.locSearch.connectivityReportData?.from - 1 || 0;
    },
    currentPage() {
      return this.$store.state.locSearch.connectivityReportData?.current_page || 1;
    },
  },
}
</script>

<style scoped></style>