<template>
  <svg
    width="16"
    height="4"
    viewBox="0 0 16 4"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.5 2.25C2.5 2.44891 2.42098 2.63968 2.28033 2.78033C2.13968 2.92098 1.94891 3 1.75 3C1.55109 3 1.36032 2.92098 1.21967 2.78033C1.07902 2.63968 1 2.44891 1 2.25C1 2.05109 1.07902 1.86032 1.21967 1.71967C1.36032 1.57902 1.55109 1.5 1.75 1.5C1.94891 1.5 2.13968 1.57902 2.28033 1.71967C2.42098 1.86032 2.5 2.05109 2.5 2.25ZM8.5 2.25C8.5 2.44891 8.42098 2.63968 8.28033 2.78033C8.13968 2.92098 7.94891 3 7.75 3C7.55109 3 7.36032 2.92098 7.21967 2.78033C7.07902 2.63968 7 2.44891 7 2.25C7 2.05109 7.07902 1.86032 7.21967 1.71967C7.36032 1.57902 7.55109 1.5 7.75 1.5C7.94891 1.5 8.13968 1.57902 8.28033 1.71967C8.42098 1.86032 8.5 2.05109 8.5 2.25ZM14.5 2.25C14.5 2.44891 14.421 2.63968 14.2803 2.78033C14.1397 2.92098 13.9489 3 13.75 3C13.5511 3 13.3603 2.92098 13.2197 2.78033C13.079 2.63968 13 2.44891 13 2.25C13 2.05109 13.079 1.86032 13.2197 1.71967C13.3603 1.57902 13.5511 1.5 13.75 1.5C13.9489 1.5 14.1397 1.57902 14.2803 1.71967C14.421 1.86032 14.5 2.05109 14.5 2.25Z"
      stroke="#A1A0A3"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "IconThreeDots",
};
</script>