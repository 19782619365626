<template src="./RegistrationForm.html"></template>
<style lang="scss">
@import "./RegistrationForm.scss";
</style>

<script>
import { Button } from "@progress/kendo-vue-buttons";
import { Grid } from "@progress/kendo-vue-grid";
import { Popup } from "@progress/kendo-vue-popup";
import { DropDownButton } from "@progress/kendo-vue-buttons";
import IconThreeDots from "@/components/svg-icons/IconThreeDots.vue";
import IconPlusBlue from "@/components/svg-icons/IconPlusBlue.vue";
import AddLinkModal from "@/components/commons/modals/add-Link-modal/AddLinkModal.vue";
import { deleteFormLinkItem } from "@/services/LocSeach.service";

export default {
  components: {
    "k-button": Button,
    grid: Grid,
    Popup,
    DropDownButton,
    IconThreeDots,
    IconPlusBlue,
    AddLinkModal,
  },
  data() {
    return {
      // showLinkModals: false,
      showOptions: false,
      // showOptions: [],
      columns: [
        { field: "name", title: "Name" },
        { field: "link", title: "Link" },
        {
          field: "Actions",
          title: "Actions",
          cell: "actionsTemplate",
          width: 112,
          className: "action-column",
        },
      ],
      passStatuses: [
        { id: 1, text: "Edit" },
        { id: 2, text: "Delete" },
      ],
      popupSettings: {
        anchorAlign: {
          horizontal: "right",
          vertical: "bottom",
        },
        popupAlign: {
          horizontal: "right",
          vertical: "top",
        },
        popupClass: "popup-design",
      },
      editId: null
    };
  },
  computed:{
    formItems() {
      return this.$store.state.locSearch.formLinkItems;
    },
    showLinkModals(){
      return this.$store.state.locSearch.showLinkModal;
    },
    anchorAlignProperty: function () {
      return {
        horizontal: "right",
        vertical: "bottom",
      };
    },
    popupAlignProperty: function () {
      return {
        horizontal: "right",
        vertical: "top",
      };
    },
  },
  mounted(){
  //   if(this.items?.length == 0){
  //     this.$store.commit("SET_REGISTRATION_FORM_COMPONENT_SHOWHIDE", false);
  //     this.$store.commit("SET_MENU_MANAGEMENT_COMPONENT_SHOWHIDE", true);
  //   }
    // this.showOptions = new Array(this.formItems?.length).fill(false);
  },
  methods: {
    showLinkModal() {
      // this.showLinkModals = !this.showLinkModals;
      this.$store.commit("SET_SHOW_LINK_MODAL", !this.showLinkModals);
    },
    closeAddLinkModal() {
      // this.showLinkModals = false;
      this.$store.commit("SET_SHOW_LINK_MODAL", false);
    },
    getNestedValue: function (fieldName, dataItem) {
      const path = fieldName.split(".");
      let data = dataItem;
      path.forEach((p) => {
        data = data ? data[p] : undefined;
      });
      
      return data;
    },
    handleOptionClick(event, props){
      if (event.item.id == 1){
        this.editId = props.dataItem.id;
        this.$store.commit("SET_SHOW_LINK_MODAL", true);
      }else if(event.item.id == 2){
        deleteFormLinkItem(props.dataItem.id)
        .then((response) => {
          this.$store.dispatch("getFormLinkData");
        })
        .catch((error) => {
          console.log(error)
        })
      }
    },
  },
};
</script>