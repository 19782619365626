<template src="./login.html" ></template>

<script>
import { Input } from '@progress/kendo-vue-inputs';
import { Button } from "@progress/kendo-vue-buttons";
import { Form, FieldWrapper, FormElement } from "@progress/kendo-vue-form";
import { Label, Error } from "@progress/kendo-vue-labels";
import IconEye from "@/components/svg-icons/IconEye.vue";
import AppGlobalLoader from "@/components/commons/app-global-loader/AppGlobalLoader.vue";
export default {
  name: "login",
  components: {
    "k-input": Input,
    'k-button': Button,
    'k-form': Form,
    'field-wrapper': FieldWrapper,
    "form-element": FormElement,
    'k-label': Label,
    'k-error': Error,
    IconEye,
    AppGlobalLoader
  },
  computed: {
    isLoggedIn() {
      return this.$store.state.auth.isLoggedIn;
    },
    errorMsg() {
      return this.$store.state.auth.errorMsg;
    },
    isLoading() {
      return this.$store.state.auth.isLoading;
    }
  },
  created() {
    if (this.isLoggedIn) {
      this.$router.push("/home");
    }
  },
  data: function () {
    return {
      model: {
        email: "",
        password: "",
      },
      passIcon: false
    };
  },
  methods: {
    goToClubDashboard() {
      this.$store.commit("SET_ERROR_MESSAGE", "");
      this.$store.commit("SET_ISLOADING_AUTH", true);
      this.$store.dispatch("getToken", this.model);
    },
    showPassHandler() {
      this.passIcon = !this.passIcon;
    }
  },
};

</script>