import { DELETE, GET, POST, PUT } from "@/service";

// export const getCreateViewDropdown = () => {
//     return GET(`api/v1/company/createView`)
// };

// export const createCompany = (payload: any) => {
//     return POST(`api/v1/company/create`, payload);
// };

export const getLookuplocIdAddressSearch = (payload: any) => {
  return GET(`api/v1/address/autocomplete?address=${payload}`);
};

export const getLookupLOCIDOutput = (payload: any) => {
  return GET(`api/v1/lookup/locid/details?location_id=${payload}`);
};

export const getLookFWRangeDetails = (payload: any) => {
  return GET(
    `api/v1/lookup/range/details?latitude=${payload.latitude}&longitude=${payload.longitude}&height=${payload.height}`
  );
};

export const getAddressUnits = () => {
  return GET(`api/v1/address/units`);
};

export const getAddressStates = () => {
  return GET(`api/v1/address/states`);
};

export const getAddressStreets = () => {
  return GET(`api/v1/address/streets`);
};

export const getAddressSearchStructured = (payload: any) => {
  return GET(
    `api/v1/address/structured/search?unit_type=${
      payload?.unit_type ? payload?.unit_type : ""
    }&street_number=${payload.street_number}&street_name=${
      payload.street_name
    }&street_type=${payload.street_type}&state=${payload.state}&town=${
      payload.town
    }&postcode=${payload.postcode ? payload.postcode : ""}&address_number=${
      payload.address_number ? payload.address_number : ""
    }`
  );
};

export const getAddressCoordinates = (payload: any) => {
  return GET(`api/v1/address/coordinates`, {
    params: payload,
  });
};

export const getTechnicalLookupOutput = (payload: any) => {
  return POST(`api/v1/lookup/technical`, payload);
};

export const getConnectivityLookupOutputService = (payload: any) => {
  return POST(`api/v1/lookup/connectivity`, payload);
};

export const connectivitySaveService = (payload: any) => {
  return POST(`api/v1/connectivity/save`, payload);
};
export const getConnectivityDetailsService = (payload: any) => {
  return GET(`api/v1/connectivity/details`, {
    params: payload,
  });
};

export const getConnectivityReportService = (payload: any) => {
  return GET(`api/v1/connectivity/reports`, {
    params: payload,
  });
};

export const getSiteLookupServices = () => {
  return GET(`api/v1/lookup/services`);
};

export const getNewSiteLookupOutput = () => {
  return GET(`api/v1/lookup/site`);
};

export const getConnectivityReports = () => {
  return GET(`api/v1/lookup/connectivity`);
};

export const getFormLinkItems = () => {
  return GET(`api/v1/form-links/get`);
};

export const createFormLinkItem = (payload: any) => {
  return POST(`api/v1/form-links/create`, payload);
};

export const updateFormLinkItem = (id: any, payload: any) => {
  return POST(`api/v1/form-links/update/${id}`, payload);
};

export const getSingleFormLinkItem = (id: any) => {
  return GET(`api/v1/form-links/show/${id}`);
};

export const deleteFormLinkItem = (id: any) => {
  return GET(`api/v1/form-links/delete/${id}`);
};

export const sendEmailPDF = (payload: any) => {
  return POST(`api/v1/connectivity/pdf/email`, payload);
};

export const getEmails = (payload: any) => {
  return GET(`api/v1/emails/get`, {
    params: payload,
  })
};
// export const getCompanyOverView = (payload: any) => {
//     return GET(`api/v1/company/${payload.id}/show`)
// };

// export const updateCompany = (payload: any) => {
//     return PUT(`api/v1/company/${payload.id}/update`, payload);
// };
