<template src="./NewSiteLookup.html"></template>

<script>
import {Button} from "@progress/kendo-vue-buttons";
import {TextArea} from "@progress/kendo-vue-inputs";
import IconQuestion from "@/components/svg-icons/IconQuestion";
import IconTower from "@/components/svg-icons/IconTower";
import IconGraph from "@/components/svg-icons/IconGraph";
import {
  Chart,
  ChartTitle,
  ChartSeries,
  ChartSeriesItem,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
} from "@progress/kendo-vue-charts";
export default {
  name: "NewSiteLookup",
  components:{
    'k-button': Button,
    'k-textarea': TextArea,
    IconQuestion,
    IconTower,
    IconGraph,
    Chart,
  ChartTitle,
  ChartSeries,
  ChartSeriesItem,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  },
  data() {
    return{
      selectedCard:false
    }
  },
  computed:{
    getNewSiteLookupOutput() {
      return this.$store.state.locSearch.getNewSiteLookupOutputdata;
    },
  },
  methods:{
    selectedCardHandler() {
      // this.selectedCard = !this.selectedCard
    },
    closeGotoRecent(){
      this.$emit("cleanData");
      this.$store.dispatch('getConnectivityReports',3);
      
    }
  }
}
</script>

<style scoped>

</style>