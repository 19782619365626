<template>
  <svg
    width="76"
    height="76"
    viewBox="0 0 76 76"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M44 14H11C8.61305 14 6.32387 14.9482 4.63604 16.636C2.94821 18.3239 2 20.6131 2 23V65C2 67.3869 2.94821 69.6761 4.63604 71.364C6.32387 73.0518 8.61305 74 11 74H53C55.387 74 57.6761 73.0518 59.364 71.364C61.0518 69.6761 62 67.3869 62 65V32M20 56L74 2L20 56ZM74 2H53H74ZM74 2V23V2Z"
      fill="#DAD9F8"
    />
    <path
      d="M44 14H11C8.61305 14 6.32387 14.9482 4.63604 16.636C2.94821 18.3239 2 20.6131 2 23V65C2 67.3869 2.94821 69.6761 4.63604 71.364C6.32387 73.0518 8.61305 74 11 74H53C55.387 74 57.6761 73.0518 59.364 71.364C61.0518 69.6761 62 67.3869 62 65V32M20 56L74 2M74 2H53M74 2V23"
      stroke="#4540DB"
      stroke-width="2.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "IconNoLink",
};
</script>