<template>
    <Notification v-if="notificationDetails?.visibility" :type="{
        style: notificationDetails?.type,
        // icon: true,
    }" :closable="true">
        <span>{{ notificationDetails?.message }}</span>
    </Notification>
</template>
  
<script>
import {
    Notification,
    NotificationGroup,
} from '@progress/kendo-vue-notification';
export default {
    name: 'NotificationComponent',
    components: {
        Notification
    },
    props: {
        notificationDetails: {
            type: Object,
            default: () => {
                return {
                    visibility: false,
                    message: 'Submitted Successfully',
                    type: 'info'
                };
            }
        }
    },
    data() {
        return {
            show: true
        };
    },
    methods: {
        onClose() {
            this.show = false;
        },
        showNotification() {
            this.show = true;
        }
    }
};
</script>