<template>
    <AppModal :close="close" :modal-title="'Site information from loxcel'" :modal-show="show">
        <template v-slot:content="">
            <div class="add-address-modal">
                <div class="content">
                    <div class="row gx-0 border-bottom-normal-1">
                        <div class="col-6">
                            <p class="font-14 mb-0 py-10">Tower Lat</p>
                        </div>
                        <div class="col-6">
                            <p class="font-14 mb-0 py-10">{{ towerInfo?.tower_lat }}</p>
                        </div>
                    </div>
                    <div class="row gx-0 border-bottom-normal-1">
                        <div class="col-6">
                            <p class="font-14 mb-0 py-10">Tower Long</p>
                        </div>
                        <div class="col-6">
                            <p class="font-14 mb-0 py-10">{{ towerInfo?.tower_long }}</p>
                        </div>
                    </div>
                    <div class="row gx-0 border-bottom-normal-1">
                        <div class="col-6">
                            <p class="font-14 mb-0 py-10">Los Clearnce</p>
                        </div>
                        <div class="col-6">
                            <p class="font-14 mb-0 py-10">{{ towerInfo?.los_clearnce }}</p>
                        </div>
                    </div>
                    <div class="row gx-0 border-bottom-normal-1">
                        <div class="col-6">
                            <p class="font-14 mb-0 py-10">Service</p>
                        </div>
                        <div class="col-6">
                            <p class="font-14 mb-0 py-10">{{ towerInfo?.service }}</p>
                        </div>
                    </div>
                    <div class="row gx-0 border-bottom-normal-1">
                        <div class="col-6">
                            <p class="font-14 mb-0 py-10">Distance from EU</p>
                        </div>
                        <div class="col-6">
                            <p class="font-14 mb-0 py-10">{{ towerInfo?.distance_eu }}</p>
                        </div>
                    </div>
                    <div class="row gx-0 border-bottom-normal-1">
                        <div class="col-6">
                            <p class="font-14 mb-0 py-10">Bearing from EU</p>
                        </div>
                        <div class="col-6">
                            <p class="font-14 mb-0 py-10">{{ towerInfo?.bearing_eu }}</p>
                        </div>
                    </div>
                    <div class="row gx-0 border-bottom-normal-1">
                        <div class="col-6">
                            <p class="font-14 mb-0 py-10">Site Loc</p>
                        </div>
                        <div class="col-6">
                            <p class="font-14 mb-0 py-10">{{ towerInfo?.site_loc }}</p>
                        </div>
                    </div>
                    <div class="row gx-0 border-bottom-normal-1">
                        <div class="col-6">
                            <p class="font-14 mb-0 py-10">Pathloss</p>
                        </div>
                        <div class="col-6">
                            <p class="font-14 mb-0 py-10">{{ towerInfo?.pathloss }}</p>
                        </div>
                    </div>
                    <div class="row gx-0 border-bottom-normal-1">
                        <div class="col-6">
                            <p class="font-14 mb-0 py-10">Tower Height</p>
                        </div>
                        <div class="col-6">
                            <p class="font-14 mb-0 py-10">{{ towerInfo?.tower_height }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <template v-slot:footer="">
            <k-button :theme-color="'primary'" @click="close">Cancel</k-button>
        </template>
    </AppModal>
</template>
  
<script>

import { Button } from "@progress/kendo-vue-buttons";
import AppModal from "@/components/commons/app-modal/AppModal";

export default {
    name: "towerInfoModal",
    components: {
        AppModal,
        'k-button': Button,
    },
    mounted() {
        // console.log(this.towerInfo)
    },
    props: {
        show: {
            type: Boolean,
            default: false
        },
        towerInfo: {
            type: Object,
            default: null
        },
        close: {
            type: Function,
            default: null
        }
    },
}
</script>
  
<style scoped></style>
  