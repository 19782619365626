<template>
  <div class="app-no-data display-center">
    <div class="text-center">
      <img src="~@/assets/images/no-content.png" alt="no-content">
      <h3 class="font-s-18 text-gray-primary font-w-normal mt-4 mb-1">No results found</h3>
      <p class="font-s-13 text-gray-quatinary font-w-normal">Please try another search</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppNoData"
}
</script>

<style scoped>

</style>