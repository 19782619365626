<div class="component-wrap">
  <div class="component-center">
    <div class="card-wrap">
      <div class="app-card">
        <div
          class="card-content"
          v-for="technicalData in getTechnicalLookupOutput?.details"
        >
          <div class="card-box" v-if="technicalData?.id == 1">
            <div class="card-left-icon">
              <IconFile />
            </div>
            <div class="card-detail">
              <div v-if="technicalData?.sub_title">
                <h3>{{technicalData?.title}}</h3>
                <p>{{ technicalData?.sub_title }}</p>
              </div>
              <div class="card-lists">
                <!-- <div class="card-list">
                                    <span>Poi name</span>
                                    <span>{{technicalData?.details?.poi_name}}</span>
                                </div>
                                <div class="card-list">
                                    <span>CSA Name</span>
                                    <span>{{technicalData?.details?.csa_name}}</span>
                                </div> -->

                <div v-if="technicalData?.details?.message" class="card-list">
                  <span>Message</span>
                  <span
                    ><strong>{{technicalData?.details?.message}}</strong></span
                  >
                </div>

                <div v-else>
                  <div class="card-list">
                    <span>Premises Locations ID</span>
                    <span
                      >{{technicalData?.details?.premises_locations_id}}</span
                    >
                  </div>
                  <div class="card-list">
                    <span>Premises Technology</span>
                    <span>{{technicalData?.details?.premises_technology}}</span>
                  </div>
                  <div class="card-list">
                    <span>Premises Service Class</span>
                    <span
                      >{{technicalData?.details?.premises_service_class}}</span
                    >
                  </div>
                  <div class="card-list">
                    <span>Premises Service Class Description</span>
                    <span
                      >{{technicalData?.details?.premises_service_description}}</span
                    >
                  </div>
                  <div class="card-list">
                    <span>Premises Readiness</span>
                    <span>{{technicalData?.details?.premises_readiness}}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="card-header border-top-normal-1"
            v-if="getTechnicalLookupOutput?.service_name && technicalData?.id == 1"
          >
            <p>{{ getTechnicalLookupOutput?.service_name }}</p>
          </div>
          <div class="card-box" v-if="technicalData?.id == 2">
            <div class="card-left-icon">
              <IconFile />
            </div>
            <div class="card-detail">
              <div>
                <h3
                  v-if="technicalData?.messages && technicalData?.messages.length > 0"
                >
                  <strong>{{technicalData?.messages}}</strong>
                </h3>
                <h3 v-else>{{technicalData?.title}}</h3>
                <p>{{ technicalData?.sub_title }}</p>
              </div>
              <div v-if="technicalData?.details?.length" class="row">
                <div class="col-md-6">
                  <div class="max-h-480 overflow-y-auto">
                    <ul>
                      <li
                        v-for="(data, index) in technicalData?.details"
                        :key="index"
                      >
                        <a
                          :class="selectedTowerIndex == index ? 'active' : '' "
                          @click="switchTowerHandler(index)"
                          href="#"
                        >
                          <span class="icon">
                            <IconTower />
                          </span>
                          <span class="text">
                            {{data?.outcome}}
                          </span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  v-if="technicalData?.details[selectedTowerIndex]?.chartData"
                  class="col-md-6"
                >
                  <div class="chart-box">
                    <Chart>
                      <ChartValueAxis>
                        <ChartValueAxisItem :major-unit="'50'" />
                      </ChartValueAxis>
                      <ChartCategoryAxis>
                        <ChartCategoryAxisItem />
                      </ChartCategoryAxis>
                      <ChartSeries>
                        <ChartSeriesItem
                          :markers="false"
                          :data-items="seriesFunc(technicalData?.details[selectedTowerIndex]?.chartData)"
                          :type="'line'"
                          :style="{ strokeWidth: 3 }"
                        />
                        <ChartSeriesItem
                          :type="'area'"
                          :data-items="technicalData?.details[selectedTowerIndex]?.chartData"
                        />
                      </ChartSeries>
                    </Chart>
                    <div class="text-center">
                      <p
                        class="max-w-408 font-s-13 font-w-normal line-h-1_4 ms-auto me-auto mb-0"
                      >
                        {{technicalData?.details[selectedTowerIndex]?.chartTitle}}
                      </p>
                    </div>
                    <div class="text-center">
                      <k-button
                        @click="chartSeeMoreHandler(technicalData?.details[selectedTowerIndex])"
                        :fill-mode="'flat'"
                        :class="'btn-text-blue'"
                        >See More</k-button
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="card-box" v-if="[3,4,5,6,8]?.includes(technicalData?.id)">
            <div class="card-left-icon">
              <IconFile />
            </div>
            <div class="card-detail">
              <div class="row">
                <div class="col-md-6">
                  <div>
                    <h3
                      v-if="technicalData?.messages && technicalData?.messages.length > 0"
                    >
                      <strong>{{technicalData?.messages}}</strong>
                    </h3>
                    <h3 v-else>{{technicalData?.title}}</h3>
                    <p>{{ technicalData?.sub_title }}</p>
                  </div>

                  <div
                    v-if="[5].includes(technicalData?.id)"
                    class="max-h-480 overflow-y-auto"
                  >
                    <ul>
                      <li
                        v-for="(data, index) in technicalData?.details?.towers"
                        :key="index"
                      >
                        <a
                          :class="selectedWispTowerIndex == index ? 'active' : '' "
                          @click="switchWispTowerHandler(index)"
                          href="#"
                        >
                          <span class="icon">
                            <IconTower />
                          </span>
                          <span class="text">
                            {{data}}
                          </span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>

                <div class="col-md-6">
                  <div
                    v-if="[5].includes(technicalData?.id) && technicalData?.details?.chartData?.length > 0"
                    class="my-3"
                  >
                    <div class="chart-box">
                      <Chart>
                        <ChartCategoryAxis>
                          <ChartCategoryAxisItem />
                        </ChartCategoryAxis>
                        <ChartSeries>
                          <ChartSeriesItem
                            :markers="false"
                            :data-items="seriesFunc(technicalData?.details?.chartData[selectedWispTowerIndex])"
                            :type="'line'"
                            :style="{ strokeWidth: 3 }"
                          />
                          <ChartSeriesItem
                            :type="'area'"
                            :data-items="technicalData?.details?.chartData[selectedWispTowerIndex]"
                          />
                        </ChartSeries>
                      </Chart>
                      <!-- <div class="text-center">
                                                <p
                                                    class="max-w-408 font-s-13 font-w-normal line-h-1_4 ms-auto me-auto mb-0">
                                                    {{connectivityData?.details[selectedTowerIndex]?.chartTitle}}</p>
                                            </div> -->
                      <!-- <div class="text-center">
                                                <k-button
                                                    @click="chartSeeMoreHandler(connectivityData?.details[selectedTowerIndex])"
                                                    :fill-mode="'flat'" :class="'btn-text-blue'">See More</k-button>
                                            </div> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="card-box" v-if="technicalData?.id == 7">
            <div class="card-left-icon">
              <IconFile />
            </div>
            <div class="card-detail">
              <div>
                <h3>{{technicalData?.title}}</h3>
                <!-- <p>{{ technicalData?.sub_title }}</p> -->
                <h3
                  v-if="technicalData?.messages && technicalData?.messages.length > 0"
                >
                  <strong>{{technicalData?.messages}}</strong>
                </h3>
                <h3 v-else>{{technicalData?.sub_title}}</h3>
              </div>
              <div v-if="technicalData?.details?.length" class="row">
                <div class="col-md-6">
                  <div class="max-h-480 overflow-y-auto">
                    <ul>
                      <li
                        v-for="(data, index) in technicalData?.details"
                        :key="index"
                      >
                        <div
                          @click="switchBroadbandHandler(index)"
                          class="list-div"
                        >
                          <span class="icon">
                            <IconTower />
                          </span>
                          <span class="text">
                            {{data?.outcome}}
                          </span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-md-6">
                  <div v-if="markerLocationsData?.length > 0" class="chart-box">
                    <googleMapPreview> </googleMapPreview>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <k-button
            @click="switchToConnectivityReportHandler"
            :theme-color="'dark'"
            :class="'ms-2'"
            >Switch To Connectivity Report</k-button
          >
          <k-button
            @click="closeGotoRecent"
            :theme-color="'dark'"
            :class="'ms-2'"
            >Close</k-button
          >
        </div>
      </div>
    </div>
  </div>
</div>

<towerInfoModal
  v-if="towerInfoModalVisibility"
  :show="towerInfoModalVisibility"
  :towerInfo="selectedTowerInfo"
  :close="closeTowerInfoModal"
>
</towerInfoModal>
