<template>
    <div :class="loaderClass">
      <Loader :theme-color="loaderColor" />
    </div>
</template>

<script>
import {Loader} from "@progress/kendo-vue-indicators";

export default {
  name: "AppGlobalLoader",

  components:{
    Loader
  },
  props:{
    loaderClass:{
      type: String,
      default:"loader-inline"
    },
    loaderColor: {
      type: String,
      default:"primary"
    },
  }
}
</script>

<style scoped>

</style>