<div class="component-wrap">
    <!-- <AppSearchLoader v-if="isLoading"/> -->
<!--    <AppNoData v-if="!getLookupFwRangeOutput"/>-->

    <div class="component-center">
        <div class="card-wrap-list">
            <div class="app-card">
                <div class="card-header">
                    <p>Site Information from loxcel</p>
                </div>
                <div class="card-content">
                    <div class="card-lists">
                        <div class="card-list">
                            <span>Tower Lat</span>
                            <span>{{getLookupFwRangeOutput?.tower_lat}}</span>
                        </div>
                        <div class="card-list">
                            <span>Tower Lon</span>
                            <span>{{getLookupFwRangeOutput?.tower_long}}</span>
                        </div>
                        <div class="card-list">
                            <span>Los Clearnce</span>
                            <span>{{getLookupFwRangeOutput?.los_clearnce}}</span>
                        </div>
                        <div class="card-list">
                            <span>Service</span>
                            <span>{{getLookupFwRangeOutput?.service}}</span>
                        </div>
                        <div class="card-list">
                            <span>Distance from EU</span>
                            <span>{{getLookupFwRangeOutput?.distance_eu}}</span>
                        </div>
                        <div class="card-list">
                            <span>Bearing from EU</span>
                            <span>{{getLookupFwRangeOutput?.bearing_eu}}</span>
                        </div>
                        <div class="card-list">
                            <span>Site Loc</span>
                            <span>{{getLookupFwRangeOutput?.site_loc}}</span>
                        </div>
                        <div class="card-list">
                            <span>Pathloss</span>
                            <span>{{getLookupFwRangeOutput?.pathloss}}</span>
                        </div>
                        <div class="card-list">
                            <span>Tower Height</span>
                            <span>{{getLookupFwRangeOutput?.tower_height}}</span>
                        </div>
                    </div>
                </div>
                <div class="card-footer">
                    <k-button :theme-color="'dark'">Close</k-button>
                </div>
            </div>
        </div>
    </div>

</div>