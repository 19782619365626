<template src="./LookupFWRange.html"></template>

<script>
import AppNoData from "@/components/commons/app-no-data/AppNoData.vue";
import AppSearchLoader from "@/components/commons/app-search-loader/AppSearchLoader.vue";
import {Button} from "@progress/kendo-vue-buttons";

export default {
  name: "LookupFWRange",
  components:{
    AppNoData,
    AppSearchLoader,
    'k-button': Button
  },
  data: function () {
    return{
    }
  },
  computed: {
    getLookupFwRangeOutput() {
      return this.$store.state.locSearch.locationFwRangeOutput;
    },
    isLookupFwRange() {
      return this.$store.state.locSearch.isLookupFwRange;
    },
    isLoading() {
      return this.$store.state.locSearch.isLoading;
    },
  },
}
</script>

<style scoped>

</style>