<template src="./AddLinkModal.html"></template>

<script>
import { Button } from "@progress/kendo-vue-buttons";
import { Input } from "@progress/kendo-vue-inputs";
import { FieldWrapper, Form, FormElement } from "@progress/kendo-vue-form";
import { Error } from "@progress/kendo-vue-labels/dist/esm/main";
import { Label } from "@progress/kendo-vue-labels";
import AppModal from "@/components/commons/app-modal/AppModal";
import { createFormLinkItem, getSingleFormLinkItem, updateFormLinkItem } from "@/services/LocSeach.service";

export default {
  components: {
    "k-button": Button,
    "k-form": Form,
    "field-wrapper": FieldWrapper,
    "form-element": FormElement,
    "k-error": Error,
    "k-input": Input,
    "k-label": Label,
    AppModal,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    close: {
      type: Function,
      default: null,
    },
    submit: {
      type: Function,
      default: null,
    },
    editId: {
      type: Number || null,
      default: null
    }
  },
  data() {
    return {
      form: {
        title: "",
        link: "",
      },
      errorMessage: {
        title: "",
        link: "",
      },
    };
  },
  mounted(){
    if(this.editId){
      getSingleFormLinkItem(this.editId)
      .then((response) => {
        this.form.title = response?.data?.data?.name
        this.form.link = response?.data?.data?.link
      })
      .catch((error) => {
        console.log(error)
      })
    }
  },
  beforeUnmount() {
    if(this.editId){
      this.$emit('update:editId', null);
      this.form.title = "";
      this.form.link = ""
    }
  },
  methods: {
    addFormLink(){
      if(this.form["title"] === "" || this.form["link"] === ""){
        if(this.form["title"] === ""){
          this.errorMessage["title"] = "Title is required";
        }
        if(this.form["link"] === ""){
          this.errorMessage["link"] = "Link is required";
        }
        return
      }

      let payload={
        name : this.form.title,
        link: this.form.link
      }
      if(this.editId){
        updateFormLinkItem(this.editId, payload)
        .then((response) => {
          this.$store.dispatch("getFormLinkData");
          if(response?.data?.success){
            this.$store.commit("SET_SHOW_LINK_MODAL", false);
            this.$store.commit("SET_MENU_MANAGEMENT_COMPONENT_SHOWHIDE", false);
            this.$store.commit("SET_REGISTRATION_FORM_COMPONENT_SHOWHIDE", true);
          }
          })
        .catch((error) => {
          this.$store.commit("SET_NOTIFICATION_DATA", {
                type: "error",
                message: "Link was not updated",
                visibility: true,
                });
        })
      }else{
        createFormLinkItem(payload)
        .then((response) => {
          this.$store.dispatch("getFormLinkData");
          if(response?.data?.success){
            this.$store.commit("SET_SHOW_LINK_MODAL", false);
            this.$store.commit("SET_MENU_MANAGEMENT_COMPONENT_SHOWHIDE", false);
            this.$store.commit("SET_REGISTRATION_FORM_COMPONENT_SHOWHIDE", true);
          }
        })
        .catch((error) => {
          this.$store.commit("SET_NOTIFICATION_DATA", {
                type: "error",
                message: "Link was not created",
                visibility: true,
                });
        })
      }
      }
    }
  };
</script>