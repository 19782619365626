<template>
    <div class="app-toast" :class="toastClass">
      <p>{{message}}</p>
      <Button @click="closeToast" :icon="'close'" :fill-mode="'flat'"/>
    </div>
</template>

<script>
import {Button} from '@progress/kendo-vue-buttons'
export default {
  name: "AppToast",
  props:{
    toastClass:{
      type: String,
      default: 'default'
    },
    message:{
      type: String,
      default: 'Submitted Successfully'
    }
  },
  components:{
    Button
  },
  methods:{
    closeToast(){
      this.$emit('')
    }
  }
}
</script>

<style scoped>

</style>