<AppGlobalLoader :loader-class="'full-screen'"
    v-if="isGlobalLoading || isConnectivitySaveLoading || isConnectivityReportLoading || isRegenerateConnectivityReportLoading" />
<notification-component v-if="notificationObject?.visibility" :notificationDetails="notificationObject" />
<div class="page-wrap page-main">
    <div class="sidebar bg-white">
        <div  class="logo cursor-pointer">
            <img @click="handleImageClick" src="~@/assets/images/logo-sm.png" alt="logo">
        </div>
        <div :class="`nav-title ${isRecentSearch || (!isMenuManagement && !isRegistrationForm && !isEmailLogs) ? 'active' : ''}`" @click="dashboardClick">
            <span class="icon">
                <IconHome />
            </span>
            <span class="title">
                Dashboard
            </span>
        </div>
        <div v-for="(item, id) in formItems" :key="id" :class="`nav-title`" @click="registrationFormClick(item)">
            <span class="icon">
                <IconRegister />
            </span>
            <span class="title">
                {{item.name}}
            </span>
        </div>
        <div class="flex-grow-1"></div>
        <div :class="`nav-title ${isEmailLogs ? 'active' : ''}`" @click="emailLogsClick">
            <span class="icon">
                <IconEmail />
            </span>
            <span class="title text-capitalize">
                Email logs
            </span>
        </div>
        <div :class="`nav-title ${isMenuManagement || isRegistrationForm ? 'active' : ''}`" @click="menuManagementClick">
            <span class="icon">
                <IconSettings />
            </span>
            <span class="title text-capitalize">
                Menu management
            </span>
        </div>
        <div class="logout">
            <k-button :class="'btn-logout'" :fill-mode="'flat'" @click="logoutHandler">
                <span class="text">Logout</span>
                <span class="icon">
                    <IconLogout />
                </span>
            </k-button>
        </div>
    </div>
    <div class="main">
        <AppWelcome :title="userInfo" />
        <div class="main-center">
            <AppSearchBar :isCLean="isCLean" @refreshCleanData="refreshCleanData" />
            <div class="component-render position-relative">

                <AppSearchLoader
                    v-if="(isLoading && !isGlobalLoading) || isTechinalLookupOutputLoading || isConnectivityLookupDataLoading" />
                <AppNoData v-if="isEmptyState && !isLoading && !isGlobalLoading" />

                <NewConnectivityReport v-if="isNewConnectivityReport" />

                <NewTechnicalLookup @cleanData="cleanData" v-if="isNewTechnicalLookup" />

                <NewSiteLookup v-if="isNewSiteLookup" />

                <RecentSearches v-if="isRecentSearch" />

                <LookupFWRange v-if="isLookupFwRange" />

                <LookupLOCID v-if="isLookupLOCID" />

                <ConrepOutput v-if="isConRepOutputModalVisible" />
                
                <EmailLogs v-if="isEmailLogs" />

                <MenuManagement v-if="isMenuManagement" />

                <RegistrationForm v-if="isRegistrationForm" />

            </div>
        </div>
    </div>

</div>