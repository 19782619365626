<template>
    <GoogleMap v-if="true" id="map" ref="mapRef" style="width: 100%; height: 500px" :api-key="map.key"
        :center="addressMapLocationData" :fullscreenControl="false" mapTypeId='satellite' :zoom="map.zoom"
        :min-zoom="map.minZoom" :max-zoom="map.maxZoom" :street-view-control="map.streetViewControl">
        <Marker :options="{ position: addressMapLocationData, draggable: true, icon: icon }" @dragend="dragendHandler"
            :key="index" />
    </GoogleMap>
</template>
  
<script>
import { GoogleMap, Marker, MarkerCluster } from 'vue3-google-map';
import IconTower from "@/components/svg-icons/IconTower";
import { configuration } from '@/configurationProvider';
export default {
    name: "googleMapDragMarker",
    components: {
        GoogleMap,
        Marker,
        MarkerCluster,
        IconTower
    },
    props: {
    },
    data() {
        return {
            icon: {
                path: "M27.648-41.399q0-3.816-2.7-6.516t-6.516-2.7-6.516 2.7-2.7 6.516 2.7 6.516 6.516 2.7 6.516-2.7 2.7-6.516zm9.216 0q0 3.924-1.188 6.444l-13.104 27.864q-.576 1.188-1.71 1.872t-2.43.684-2.43-.684-1.674-1.872l-13.14-27.864q-1.188-2.52-1.188-6.444 0-7.632 5.4-13.032t13.032-5.4 13.032 5.4 5.4 13.032z",
                fillColor: '#E32831',
                fillOpacity: 1,
                strokeWeight: 0,
                scale: 0.65
            },
            map: {
                key: configuration?.google_map_API_key, // Place a Google API Key here
                zoom: 12,
                minZoom: 2,
                maxZoom: null,
                streetViewControl: false
            },
        }
    },
    computed: {
        locationData() {
            return this.$store.state.locSearch.locationData;
        },
        markerLocationsData() {
            return this.$store.state.locSearch.markerLocationsData;
        },
        addressMapLocationData() {
            return this.$store.state.locSearch.addressMapLocationData;
        },
        selectedAddressForSearch() {
            return this.$store.state.locSearch.selectedAddressForSearch;
        },
    },
    watch: {

    },
    methods: {
        dragendHandler(e) {
            // console.log('dragendHandler -- c92', e?.latLng?.lat(), e?.latLng?.lng());
            this.$store.commit("SET_SELECTED_ADDRESS_FOR_SEARCH", {
                ...this.selectedAddressForSearch,
                lat: e?.latLng?.lat(),
                lng: e?.latLng?.lng(),
            });

        }

    }
}
</script>
  
<style scoped></style>
  