<template>
    <div class="welcome-head">
      <h3 class="font-s-27 font-w-normal mb-2">Welcome {{title?.name}}</h3>
      <p class="font-s-13">{{ Date() }}</p>
    </div>
</template>

<script>
export default {
  name: "AppWelcome",
  props:{
    title:{
      type: String,
      default: ''
    }
  }
}
</script>

<style scoped>

</style>