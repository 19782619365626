const notificationHandler = (message, isvisible, type) => {
  return {
    message: message,
    isNotify: isvisible,
    type: type,
  };
};

export { notificationHandler };

const dateFormatter = (date, format) => {
  const moment = require("moment");
  return moment(date).format(format ? format : "DD-MM-YYYY");
};

function randomStringGenerator() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  );
}

export { dateFormatter, randomStringGenerator };
