<template src="./NewConnectivityReport.html"></template>
<script>
import { Button } from "@progress/kendo-vue-buttons";
import IconQuestion from "@/components/svg-icons/IconQuestion";
import IconGraph from "@/components/svg-icons/IconGraph";
import IconTower from "@/components/svg-icons/IconTower";
import IconPage from "@/components/svg-icons/IconPage";
import { Input, TextArea } from '@progress/kendo-vue-inputs'
import AppModal from "@/components/commons/app-modal/AppModal";
import { FieldWrapper, Form, FormElement } from "@progress/kendo-vue-form";
import { Error } from "@progress/kendo-vue-labels/dist/esm/main";
import { Label } from "@progress/kendo-vue-labels";
import {
  ChartSeriesMarkers,
  ChartValueAxisTitle,
  ChartValueAxisLabels,
  Chart,
  ChartSeries,
  ChartSeriesItem,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartTitle,
  ChartTooltip,
  ChartValueAxis,
  ChartValueAxisItem,
  exportVisual,
} from "@progress/kendo-vue-charts";
import googleMapPreview from "@/components/commons/google-map-preview/googleMapPreview.vue";
import towerInfoModal from "@/components/commons/modals/tower-info-modal/towerInfoModal.vue";
import userDetailsModal from '@/components/commons/modals/user-details-modal/userDetailsModal.vue';
import { saveAs } from '@progress/kendo-file-saver';
import { exportImage } from '@progress/kendo-drawing';
export default {
  name: "NewConnectivityReport",
  components: {
    'k-button': Button,
    'k-textarea': TextArea,
    'k-form': Form,
    'field-wrapper': FieldWrapper,
    "form-element": FormElement,
    'k-error': Error,
    'k-input': Input,
    'k-label': Label,
    IconQuestion,
    IconTower,
    IconGraph,
    IconPage,
    AppModal,
    ChartSeriesMarkers,
    ChartValueAxisTitle,
    ChartValueAxisLabels,
    Chart,
    ChartSeries,
    ChartSeriesItem,
    ChartCategoryAxis,
    ChartCategoryAxisItem,
    ChartTitle,
    ChartTooltip,
    ChartValueAxis,
    ChartValueAxisItem,
    googleMapPreview,
    towerInfoModal,
    userDetailsModal,
  },
  data() {
    return {
      selectedCard: false,
      showUserModals: false,
      selectedTowerIndex: 0,
      selectedWispTowerIndex: 0,
      isEditModeEnabled: false,
      selectedTowerInfo: null,
      towerInfoModalVisibility: false,
      chartImgBase64: "",
    }
  },
  computed: {
    connectivityLookupData() {
      return this.$store.state.locSearch.connectivityLookupData;
    },
    connectivityResponseData() {
      return this.$store.state.locSearch.connectivityResponse;
    },
    preservedConnectivityLookupData() {
      return this.$store.state.locSearch.preservedConnectivityLookupData;
    },
    markerLocationsData() {
      return this.$store.state.locSearch.markerLocationsData;
    },
    appSearchData() {
      return this.$store.state.locSearch.appSearchData;
    },
  },
  mounted() {
    this.selectedTowerIndex = this.connectivityResponseData?.details?.selectedTower ? this.connectivityResponseData?.details?.selectedTower : 0;
  },
  methods: {
    seriesFunc(item) {
      let item2 = JSON.parse(JSON.stringify(item));
      item2?.forEach((element, index) => {
        if (index !== 0 && index !== (item2?.length - 1)) {
          item2[index] = null;
        }
      });
      return item2;
    },
    onSubmitUserModal(userData) {
      this.showUserModals = false;
      const payload = {
        search: this.appSearchData,
        customer: userData,
        images: {
          chart_image_name: "chart.png",
          chart_image_data: this.chartImgBase64
        },
        details: this.connectivityLookupData.details,
        selectedTower: this.selectedTowerIndex
      }
      this.connectivityLookupData?.details?.map((item) => {
        if(item?.id == 2){
          item.service_id = 1;
        }else if(item?.id == 3){
          item.service_id = 2;
        }else if(item?.id == 4){
          item.service_id = 3;
        }else if(item?.id == 5){
          item.service_id = 4;
        }else if(item?.id == 6){
          item.service_id = 5;
        }else if(item?.id == 7){
          item.service_id = 6;
        }
      })
      this.$store.dispatch("saveConnectivityData", payload);
    },
    serviceDataHandleChange(e, index) {
      if ([1, 2, 3, 4, 5, 6].includes(index)) {
        this.connectivityLookupData.details[index].services[0].details = e.value;
      } else if ([8, 9].includes(index)) {
        this.connectivityLookupData.details[index].sub_title = e.value;
      }
    },
    connectivityDataHandleChange(e, index, indexService) {
      this.connectivityLookupData.details[7].services[indexService].details = e.value;
    },
    closeUserDetailsModal() {
      this.showUserModals = false;
    },
    onCancelEditMode() {
      this.isEditModeEnabled = false;
      this.$store.commit("SET_CONNECTIVITY_LOOKUP_DATA", structuredClone(this.preservedConnectivityLookupData));
    },
    editOptionHandler() {
      this.isEditModeEnabled = true;
      this.getChartImage();
      this.$store.commit("SET_PRESERVED_CONNECTIVITY_LOOKUP_DATA", structuredClone(this.connectivityLookupData));
    },
    chartSeeMoreHandler(towerData) {
      this.selectedTowerInfo = towerData;
      this.towerInfoModalVisibility = true;
    },
    closeTowerInfoModal() {
      this.towerInfoModalVisibility = false;
    },
    switchTowerHandler(index) {
      this.selectedTowerIndex = index;
    },
    switchWispTowerHandler(index) {
      this.selectedWispTowerIndex = index;
    },
    handleChange(e) {
    },
    selectedCardHandler(index) {
      this.connectivityLookupData.details[index].is_selected = this.connectivityLookupData.details[index].is_selected == 1 ? 0 : 1;
    },
    selectedCardHandlerDataService(index) {
      index.is_selected = index.is_selected == 1 ? 0 : 1;
    },
    selectedConnectivityData(index) {
      this.connectivityLookupData.details[index].is_selected = this.connectivityLookupData.details[index].is_selected == 1 ? 0 : 1;
    },
    gotoRecent() {
      // this.$store.dispatch("getConnectivityReportsData", {});
      // this.$store.commit("SET_NEWCONNECTIVITYREPORT_COMPONENT_SHOWHIDE", false);

      this.$store.commit("SET_GLOBAL_SEARCH_DATA", {
        searchType: null,
        address: {
          id: "",
          formattedAddress: ""
        },
        coordinates: "",
        antennaHeight: "",
      });
      this.$store.dispatch("getConnectivityReportsData", {});
      this.$store.commit("SET_ISEMPTY_STATE", false);
      this.$store.commit("SET_LookupLOCID_COMPONENT_SHOWHIDE", false);
      this.$store.commit("SET_LOOKUPFWRANGE_COMPONENT_SHOWHIDE", false);
      this.$store.commit("SET_NEWCONNECTIVITYREPORT_COMPONENT_SHOWHIDE", false);
      this.$store.commit("SET_NEWTECHNICALLOOKUP_COMPONENT_SHOWHIDE", false);
      this.$store.commit("SET_NEWSITELOOKUP_COMPONENT_SHOWHIDE", false);
      this.$store.commit('SET_RECENTSEARCH_COMPONENT_SHOWHIDE', true);
      this.$store.commit("SET_MENU_MANAGEMENT_COMPONENT_SHOWHIDE", false);
      this.$store.commit("SET_REGISTRATION_FORM_COMPONENT_SHOWHIDE", false);
    },
    showUserModal() {
      // console.log(this.connectivityLookupData, "c92");
      // return
      this.showUserModals = !this.showUserModals;
      this.getChartImage();
    },
    exportChartVisual(callback) {
      const chartVisual = exportVisual(this.$refs?.exportChart[0], {});
      if (chartVisual) {
        callback(chartVisual);
      }
    },
    getChartImage() {
      this.exportChartVisual((chartVisual) =>
        exportImage(chartVisual).then((dataURI) => {
          this.chartImgBase64 = dataURI;
        })
      );
    },
  }
}
</script>

<style scoped></style>