<div class="component-wrap">

    <div class="component-center">
        <div class="card-wrap-list">
            <div class="app-card">
                <div class="card-header">
                    <p>Conrep Output</p>
                </div>
                <div class="card-content">
                    <div class="card-lists">
                        <div class="card-list">
                            <span>Loc ID</span>
                            <span>{{conRepOutputData?.loc_id}}</span>
                        </div>
                        <div class="card-list">
                            <span>Address</span>
                            <span>{{conRepOutputData?.address}}</span>
                        </div>
                        <div class="card-list">
                            <span>Summary</span>
                            <span>{{conRepOutputData?.summary}}</span>
                        </div>
                        <div class="card-list">
                            <span><strong>Files</strong></span>
                        </div>
                        <!-- <div class="card-list">
                            <div class="file-card">
                                <div class="icon">
                                    <IconDoc />
                                </div>
                                <div class="text">
                                    <p>{{conRepOutputData?.doc_name}}</p>
                                    <span>{{conRepOutputData?.doc_size}}</span>
                                </div>
                            </div>

                            <k-button @click="downloadFile(conRepOutputData?.doc_download)" :theme-color="'primary'"
                                :fill-mode="'flat'">Download</k-button>
                        </div> -->
                        <div class="card-list">
                            <div class="file-card">
                                <div class="icon">
                                    <IconPDF />
                                </div>
                                <div class="text">
                                    <p>{{conRepOutputData?.pdf_name}}</p>
                                    <span>{{conRepOutputData?.pdf_size}}</span>
                                </div>
                            </div>
                            <k-button @click="downloadFile(conRepOutputData?.pdf_download)" :theme-color="'primary'"
                                :fill-mode="'flat'">Download</k-button>
                            <k-button @click="setShowEmailModal" :theme-color="'primary'"
                                :fill-mode="'flat'">Email PDF</k-button>
                        </div>
                    </div>
                </div>
                <div class="card-footer">
                    <k-button @click="gotoRecent" :theme-color="'dark'">Close</k-button>
                </div>
            </div>
        </div>
    </div>

    <sendEmailModal v-if="showEmailModal" :path="conRepOutputData?.pdf_download" :show="setShowEmailModal"
    :close="closeEmailModal"></sendEmailModal>
</div>