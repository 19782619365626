<template>
  <router-view />
</template>

<script>

import AppToast from "@/components/commons/app-toast/AppToast";
export default {
  name: "App",
  components: {
    AppToast
  },
  data: function () {
    return {
      appRender: false,
    };
  },
  created(){
    this.$store.dispatch("checkToken");
  },
};
</script>

<style lang='scss'>
@import "./assets/scss/helpers/variables";
@import "~@progress/kendo-theme-bootstrap/dist/all.scss";
@import "~bootstrap/scss/bootstrap.scss";
@import "./assets/scss/app.scss";
</style>