import Vuex from "vuex";
import auth from "./auth/auth";
import locSearch from "./locSearch/locSearch";

export default new Vuex.Store({
  modules: {
    auth,
    locSearch
  },
});
