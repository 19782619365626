import { GET, POST } from "@/service";
import router from "@/router";
import { configuration } from "@/configurationProvider";
import axios from "axios";

export type State = { isLoggedIn: boolean; errorMsg: any; isLoading: boolean };

const state: State = {
  isLoggedIn: false,
  errorMsg: "",
  isLoading: false,
};

const auth = {
  state,
  getters: {
    isAuthenticated: (state: any) => !!state.isLoggedIn,
  },
  mutations: {
    GET_TOKEN(state: { isLoggedIn: boolean }, data: any) {
      state.isLoggedIn = data;
    },
    INVALID_TOKEN() {
      state.isLoggedIn = false;
    },
    SET_ERROR_MESSAGE(state: { errorMsg: [] }, data: any) {
      state.errorMsg = data;
    },
    SET_ISLOADING_AUTH(state: { isLoading: boolean }, data: any) {
      state.isLoading = data;
    },
  },
  actions: {
    getToken({ commit }: any, payload: any) {
      POST(`api/v1/login`, payload)
        .then((response) => {
          console.log('response.data.data.user',response.data.data.user);
          
          commit("SET_ISLOADING_AUTH", false);
          if (!response.data.data.token) {
            commit("INVALID_TOKEN", response.data.data);
          } else {
            localStorage.setItem(
              "rth_session",
              response.data.data.token.access_token
            );
            localStorage.setItem(
              "rth_refreshToken",
              response.data.data.token.refresh_token
            );
            localStorage.setItem(
              "rth_user", JSON.stringify(response.data.data.user)
            );
            commit("GET_TOKEN", true);
            router.push("/home");
          }
        })
        .catch((error) => {
          commit("SET_ISLOADING_AUTH", false);
          commit("SET_ERROR_MESSAGE", error?.response?.data?.data);
        });
    },
    signOut({ commit, dispatch }: any, payload: any) {
      GET(`api/v1/logout`)
        .then((response) => {
          localStorage.removeItem("rth_session");
          localStorage.removeItem("rth_refreshToken");
          localStorage.removeItem("rth_user");
          router.go(0);
          dispatch("checkToken");
        })
        .catch((error) => {
          commit("SET_ERROR_MESSAGE", error.response.data.result.message);
        });
    },
    getTokenUsingRefreshToken({ commit }: any) {
      let payload = {
        refresh_token: localStorage.getItem("rth_refreshToken"),
      };
      POST(`api/v1/refresh`, payload)
        .then((response) => {
          if (response?.data?.data?.token) {
            localStorage.setItem(
              "rth_session",
              response.data.data.token.access_token
            );
            localStorage.setItem(
              "rth_refreshToken",
              response.data.data.token.refresh_token
            );
            commit("GET_TOKEN", true);
            router.go(0);
          }
        })
        .catch((error) => {
          // console.log('error', error);
          commit("GET_TOKEN", false);
          localStorage.removeItem("rth_session");
          localStorage.removeItem("rth_refreshToken");
          localStorage.removeItem("rth_user");
          router.push("/");
        });
    },
    checkToken({ commit }: any, payload: any) {
      let token = localStorage.getItem("rth_session");
      if (token) {
        commit("GET_TOKEN", true);
      } else {
        // console.log(token, "AAA");
        commit("GET_TOKEN", false);
        router.push("/");
      }
    },
  },
};
export default auth;
