<div class="component-wrap">
    <div class="component-center">
        <div class="search-recent-wrap">
            <div class="search-recent-head d-flex align-items-center mb-3">
                <h3 class="text-capitalize">add form links</h3>
                <div class="flex-grow-1"></div>
                <k-button @click="showLinkModal" :fill-mode="'outline'" class="px-24"><IconPlusBlue class="me-2" /> Add New Link</k-button>
            </div>
            <div class="search-recent-grid">
                <grid class="registration-form-grid" :data-items="formItems" :columns="columns">
                    <template v-slot:actionsTemplate="{props}">
                        <td :class="props.className">
                            <div class="d-flex justify-content-end">
                                <DropDownButton :size="'medium'" :popup-settings="popupSettings" :icon="'more-horizontal'" :fill-mode="flat" :rounded="full"
                                :items="passStatuses"
                                :class="'drop-down-btn'"
                                @itemclick="handleOptionClick($event,props)" />
                            </div>
                        </td>
                    </template>
                </grid>
            </div>
        </div>
    </div>
    
    <AddLinkModal v-if="showLinkModals" :show="showLinkModals"
        :close="closeAddLinkModal" :editId="editId" @update:editId="editId = $event" />
</div>