<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2 7.70316C2 7.3748 2.16713 7.06676 2.44851 6.87647L9.38184 2.18771C9.75193 1.93743 10.2481 1.93743 10.6182 2.18771L17.5515 6.87647C17.8329 7.06676 18 7.3748 18 7.70316V16.4784C18 17.3188 17.2837 18 16.4 18H3.6C2.71634 18 2 17.3188 2 16.4784V7.70316Z" stroke="" stroke-width="2"/>
    <path d="M12 11C12 12.1046 11.1046 13 10 13C8.89543 13 8 12.1046 8 11C8 9.89543 8.89543 9 10 9C11.1046 9 12 9.89543 12 11Z" stroke="" stroke-width="2"/>
  </svg>
</template>

<script>
export default {
  name: "IconHome"
}
</script>