<template>
  <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M16.6875 1.9375C16.4375 1.6875 16.0625 1.6875 15.8125 1.9375C15.5625 2.1875 15.5625 2.5625 15.8125 2.8125C18 5 18 8.5 15.8125 10.6875C15.5625 10.9375 15.5625 11.3125 15.8125 11.5625C15.9375 11.6875 16.125 11.75 16.25 11.75C16.375 11.75 16.5625 11.6875 16.6875 11.5625C19.375 8.9375 19.375 4.5625 16.6875 1.9375Z" fill="#5A5C60"/>
  <path d="M13.75 8.375C13.5 8.625 13.5 9 13.75 9.25C13.875 9.375 14.0625 9.4375 14.1875 9.4375C14.3125 9.4375 14.5 9.375 14.625 9.25C15.3125 8.5625 15.6875 7.6875 15.625 6.6875C15.625 5.75 15.1875 4.8125 14.5 4.125C14.25 3.875 13.875 3.875 13.625 4.125C13.375 4.375 13.375 4.75 13.625 5C14.5625 5.9375 14.625 7.5 13.75 8.375Z" fill="#5A5C60"/>
  <path d="M4.1875 10.6875C2 8.5 2 5 4.1875 2.8125C4.4375 2.5625 4.4375 2.1875 4.1875 1.9375C3.9375 1.6875 3.5625 1.6875 3.3125 1.9375C0.625 4.5625 0.625 8.9375 3.3125 11.5625C3.4375 11.6875 3.5625 11.75 3.75 11.75C3.9375 11.75 4.0625 11.6875 4.1875 11.5625C4.4375 11.3125 4.4375 10.9375 4.1875 10.6875Z" fill="#5A5C60"/>
  <path d="M5.5 9.375C5.625 9.5 5.8125 9.5625 5.9375 9.5625C6.0625 9.5625 6.25 9.5 6.375 9.375C6.625 9.125 6.625 8.75 6.375 8.5C5.4375 7.5625 5.375 6 6.25 5.125C6.5 4.875 6.5 4.5 6.25 4.25C6 4 5.625 4 5.375 4.25C4.6875 4.9375 4.375 5.8125 4.375 6.8125C4.375 7.75 4.8125 8.6875 5.5 9.375Z" fill="#5A5C60"/>
  <path d="M15 18H13.625L11.125 8.25C11.5625 7.9375 11.875 7.375 11.875 6.75C11.875 5.6875 11.0625 4.875 10 4.875C8.9375 4.875 8.125 5.6875 8.125 6.75C8.125 7.375 8.4375 7.9375 8.9375 8.25L6.4375 18H5C4.625 18 4.375 18.25 4.375 18.625C4.375 19 4.625 19.25 5 19.25H15C15.375 19.25 15.625 19 15.625 18.625C15.625 18.25 15.375 18 15 18ZM11 13H8.9375L9.9375 9.0625L11 13ZM8.6875 14.25H8.75H11.25C11.3125 14.25 11.3125 14.25 11.3125 14.25L12.3125 18H7.6875L8.6875 14.25Z" fill="#5A5C60"/>
  </svg>
</template>

<script>
export default {
  name: "IconTower"
}
</script>

<style scoped>

</style>