<div class="page-wrap login-wrap">
    <div class="login-left">
        <div class="logo">
            <img src="@/assets/images/logo.png" alt="logo">
        </div>
    </div>
    <div class="login-right">
        <div class="log-box">
            <div class="title text-center mb-5">
                <h3 class="font-w-semi-bold font-s-34 text-base-black">Login</h3>
                <p class="font-w-normal font-s-14 text-gray-quatinary mb-0">Into CONREP</p>
            </div>
            <div class="login">
                <k-form>
                    <k-error class="form-alert-position global-erroe" v-if="errorMsg!=null">{{errorMsg?.error}}</k-error>
                    <form-element>
                        <field-wrapper>
                            <k-label>User Name</k-label>
                            <div class="k-form-field-wrap">
                                <k-input :placeholder="'Enter user name'" v-model="model.email" />
                                <k-error v-for="sError in errorMsg?.email" class="form-alert-position" v-if="errorMsg!=null">{{sError}}</k-error>
                            </div>
                        </field-wrapper>
                        <field-wrapper>
                            <k-label>Password</k-label>
                            <div class="k-form-field-wrap">
                                <span class="icon-right">
                                    <k-button type="button" @click="showPassHandler" :class="'btn-no-hover'" :fill-mode="'flat'" >
                                        <span v-if="passIcon">
                                            <img src="~@/assets/images/eye-off.png" alt="eye.png">
                                        </span>
                                        <span v-else>
                                            <img src="~@/assets/images/eye.png" alt="eye.png">
                                        </span>
                                    </k-button>
                                </span>
                                <k-input :type="passIcon ? 'text' : 'password'" :placeholder="'Enter password'" v-model="model.password" />
                                <k-error v-for="sError in errorMsg?.password" class="form-alert-position" v-if="errorMsg!=null">{{sError}}</k-error>
                            </div>
                        </field-wrapper>
                        <field-wrapper>
                            <div class="k-form-field-wrap">
                                <k-button :theme-color="'primary'" :class="'w-100'" @click="goToClubDashboard">
                                    <AppGlobalLoader :loaderColor="'solid'" v-if="isLoading" /> 
                                    {{isLoading? "":"Login"}}
                                </k-button>
                            </div>
                        </field-wrapper>
                    </form-element>
                </k-form>
            </div>
        </div>
    </div>
</div>