<div class="component-wrap">
    <div class="h-100 display-center">
        <div class="text-center">
            <div class="display-center no-link-icon w-148px h-148px bg-white rounded-full">
                <IconNoLink />
            </div>
            <h3 class="font-s-18 line-h-1_4 text-gray-primary font-w-normal mt-8 mb-0">No links found</h3>
            <p class="font-s-13 text-gray-quatinary font-w-normal">Please try another search</p>
            <k-button @click="showLinkModal" :theme-color="'primary'" class="px-24"><IconPlus class="me-2" /> Add Link</k-button>
        </div>
    </div>
    <AddLinkModal v-if="showLinkModals" :show="showLinkModals"
        :close="closeAddLinkModal" />
</div>