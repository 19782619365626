<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.999 16.8004V16.8426M9.60002 9.04572C9.60002 7.6952 10.6745 6.60039 12 6.60039C13.3255 6.60039 14.4 7.6952 14.4 9.04572C14.4 10.3962 13.3255 11.491 12 11.491C12 11.491 11.999 12.2209 11.999 13.1213M21.6 12.0004C21.6 17.3023 17.302 21.6004 12 21.6004C6.69809 21.6004 2.40002 17.3023 2.40002 12.0004C2.40002 6.69846 6.69809 2.40039 12 2.40039C17.302 2.40039 21.6 6.69846 21.6 12.0004Z" stroke="#5A5C60" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "IconQuestion"
}
</script>

<style scoped>

</style>