<template src="./AppModal.html"></template>
<script>
import { Dialog, DialogActionsBar } from '@progress/kendo-vue-dialogs';
export default {
  name: "AppModal",
  components: {
    'k-dialog': Dialog,
    'dialog-actions-bar': DialogActionsBar,
  },
  props: {
    modalShow: {
      type: Boolean,
      required: true,
    },
    modalTitle: {
      type: String,
      default: 'modal title'
    },
    modalClass: {
      type: String,
      default: 'modal-small',
    },
    close: {
      type: Function,
      default: null
    }
  },
  data: function () {
    return {
      visibleDialog: false
    };
  },
  methods: {
    closeModal() {
      this.$emit("modalHandler", false);
    },
  }
}
</script>

<style scoped></style>