import axios from "axios";
import { configuration } from "./configurationProvider";
import router from '@/router';
import store from '@/store';
export const HTTP = axios.create({ baseURL: configuration.apiBaseUrl });
HTTP.interceptors.request.use(
  async (config) => {
    let token = null;
    token = localStorage.getItem("rth_session");

    config.headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
      "Access-Control-Allow-Headers": "Origin, Content-Type, X-XSRF-TOKEN",
    };
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

HTTP.interceptors.response.use(
  async (res) => {
    if (res.status == 401) {
      // await auth.login();
      return;
    }
    // if (res.status == 403) {
    //   window.location.href = "/";
    // }
    return res;
  },
  (err) => {
    if (err.response.status == 401) {
      store.dispatch("getTokenUsingRefreshToken");
    }
    return Promise.reject(err);
  }
);

export function GET(url, queryPayload = {}) {
  return HTTP.get(`${configuration.apiBaseUrl}/${url}`, queryPayload);
}

export function POST(url, body) {
  return HTTP.post(`${configuration.apiBaseUrl}/${url}`, body);
}

export function PUT(url, body) {
  return HTTP.put(`${configuration.apiBaseUrl}/${url}`, body);
}

export function DELETE(url, queryPayload = {}) {
  return HTTP.delete(`${configuration.apiBaseUrl}/${url}`, queryPayload);
}
