<AppModal :close="close" :modal-title="'Add Link'" :modal-show="show">
    <template v-slot:content="">
        <div class="content">
            <k-form>
                <form-element>
                    <div class="row">
                        <div class="col-md-12">
                            <field-wrapper>
                                <k-label>Title</k-label>
                                <div class="k-form-field-wrap">
                                    <k-input @keypress="this.errorMessage['title'] = ''" v-model="form.title" />
                                    <k-error class="form-alert-position">{{ this.errorMessage['title']
                                        }}</k-error>
                                </div>
                            </field-wrapper>
                        </div>
                        <div class="col-md-12">
                            <field-wrapper>
                                <k-label>Add Link</k-label>
                                <div class="k-form-field-wrap">
                                    <k-input @keypress="this.errorMessage['link'] = ''" v-model="form.link" />
                                    <k-error class="form-alert-position">{{ this.errorMessage['link']
                                        }}</k-error>
                                </div>
                            </field-wrapper>
                        </div>
                    </div>
                </form-element>
            </k-form>
        </div>
    </template>
    <template v-slot:footer="">
        <k-button :theme-color="'dark'" @click="close">Cancel</k-button>
        <k-button @click="addFormLink" :theme-color="'primary'">Save</k-button>
    </template>
</AppModal>