<template>
  <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.3999 8.02889C13.3999 9.31867 12.3254 10.3642 10.9999 10.3642C9.67442 10.3642 8.5999 9.31867 8.5999 8.02889C8.5999 6.7391 9.67442 5.69353 10.9999 5.69353C12.3254 5.69353 13.3999 6.7391 13.3999 8.02889Z" stroke="#404040" stroke-width="2"/>
  </svg>

</template>

<script>
export default {
  name: "IconEye"
}
</script>

<style scoped>

</style>