<div class="component-wrap">
    <div class="component-center">
        <div class="search-recent-wrap">
            <div class="search-recent-head">
                <h3 class="text-capitalize">Email logs</h3>
            </div>
            <div class="search-recent-grid">
                <grid :data-items="emailList" :columns="columns" :skip="skip" :take="take"
                :total="total" :pageable="gridPageable" @pagechange="pageChangeHandler">
                    <template v-slot:timeTemplate="{props}">
                        <td :class="props.className">
                            <span>{{formatDate(props.dataItem.created_at)}}</span>
                        </td>
                    </template>
                    <template v-slot:actionsTemplate="{props}">
                        <td :class="props.className">
                            <k-button :fill-mode="'flat'" :theme-color="(props.dataItem.status == 'bounce' || props.dataItem.status == 'dropped') ? 'error' : 'success'">{{capitalizeStatus(props.dataItem.status)}}</k-button>
                        </td>
                    </template>
                </grid>
            </div>
        </div>
    </div>
</div>