<template src="./MenuManagement.html"></template>

<script>
import { Button } from "@progress/kendo-vue-buttons";
import IconNoLink from "@/components/svg-icons/IconNoLink.vue";
import IconPlus from "@/components/svg-icons/IconPlus.vue";
import AddLinkModal from '@/components/commons/modals/add-Link-modal/AddLinkModal.vue';

export default {
  components: {
    "k-button": Button,
    IconNoLink,
    IconPlus,
    AddLinkModal
  },
  data() {
    return {
      // showLinkModals: false,
    };
  },
  computed: {
    showLinkModals(){
      return this.$store.state.locSearch.showLinkModal;
    }
  },
  methods: {
    showLinkModal() {
      this.$store.commit("SET_SHOW_LINK_MODAL", !this.showLinkModals)
    },
    closeAddLinkModal() {
      this.$store.commit("SET_SHOW_LINK_MODAL", false)
    },
  },
};
</script>