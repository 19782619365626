import Login from '../../components/auth/login/login.vue'

const loginRoute = [
    {
        path: '/',
        name: 'Login',
        component: Login
    }
];

export default loginRoute;