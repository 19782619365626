import { createRouter, createWebHistory } from "vue-router";
import Home from '../components/system-dashboard/systemDashboard.vue'
import LoginRoute from './auth/LoginRoute.js'
import store from '../store/index'
const routes = [
  {
    path: '/home',
    name: 'Home',
    component: Home,
    meta: { requiresAuth: true },
  },
  ...LoginRoute
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.requiresAuth)) {
     if(!store.getters.isAuthenticated) {
        next({
           path: '/',
        });
     }
     next();
  } else {
     next();
  }
});

export default router;
